import {stringify, parseUrl} from 'query-string';

const toQueryString = (queryParams) => {
  return stringify(queryParams);
};

const getQueryParamValue = (url, paramName) => {
  var segments = parseUrl(url);
  return segments.query[paramName];
};

export {toQueryString, getQueryParamValue};
