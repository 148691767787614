import React, {createRef} from 'react';
import {observer, inject} from 'mobx-react';
import NavigatorOnline from 'react-navigator-online';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ParticipantsList from './participantsList';
import MainVideoContainer from './mainVideoContainer';
import MediaControl from './mediaControl';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import {MOBILE_OR_SIMILAR} from '../Constants/deviceTypes';
import {
  MEETING_PAGE_INTERNET_BACK_UP_MSG,
  MEETING_PAGE_LOST_INTERNET,
  MEETING_PAGE_INTERNET_RECONNECTING,
  MEETING_PAGE_EXIT_MEETING_ALERT_MSG,
} from '../Constants/textResources';
import {SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE} from '../Helpers/configHelper';

const responsiveOverrides = {
  MOBILE_OR_SIMILAR: {
    position: 'relative',
    marginTop: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'none !important',
    width: 'inherit',
  },
};

class MeetingView extends React.Component {
  constructor(props) {
    super(props);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.videoIsAvailable = this.videoIsAvailable.bind(this);
    this.screencastIsAvailable = this.screencastIsAvailable.bind(this);
    this.toggleMediaState = this.toggleMediaState.bind(this);
    this.meetingSessionIsActive = this.meetingSessionIsActive.bind(this);
    this.getResponsiveOverrides = this.getResponsiveOverrides.bind(this);
    this.getControls = this.getControls.bind(this);

    this.state = {
      message_online: MEETING_PAGE_INTERNET_BACK_UP_MSG,
      message_offline: MEETING_PAGE_LOST_INTERNET,
      message_reconnecting: MEETING_PAGE_INTERNET_RECONNECTING,
    };
    this.mainVideo = createRef();
  }

  onBeforeUnload = (e) => {
    e.returnValue = MEETING_PAGE_EXIT_MEETING_ALERT_MSG;
    return MEETING_PAGE_EXIT_MEETING_ALERT_MSG;
  };

  toggleMediaState = async (mediaKind) => {
    await this.props.context.Conference.toggleMediaState(mediaKind);
  };

  videoIsAvailable = () => this.props.context.Conference.eventData.isVideoSupported;
  screencastIsAvailable = () =>
    this.props.context.Conference.eventData.isScreencastSupported;

  meetingSessionIsActive = () => {
    return this.props.meetingIsLive;
  };

  componentDidMount() {
    //meeting view render is complete
    //attach any pending AV tracks to thumbnails and main video container
    var count = 0;
    var interval = setInterval(() => {
      this.props.context.Conference.attachAnyPendingThumnailTracks(
        (allPendingTracksAttached) => {
          if (allPendingTracksAttached || count >= 10) {
            clearInterval(interval);
            interval = null;
          }
        }
      );
      count++;
    }, 200);

    window.addEventListener('beforeunload', this.onBeforeUnload);

    //In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead
    if (this.props.context.Conference.eventData.isMobile) {
      window.addEventListener('onpagehide', this.onBeforeUnload);
    }
  }

  isVideoPaused = (participantSid) => this.props.isVideoPaused(participantSid);

  getResponsiveOverrides = () => {
    if (
      this.props.deviceType === MOBILE_OR_SIMILAR &&
      SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE
    ) {
      return responsiveOverrides[this.props.deviceType];
    }

    return {};
  };

  handleInternetConnectionChange = (status) => {
    if (!status) {
      if (!this.state.internetConnectionLost) {
        toast.error(this.state.message_offline);
      }
      this.setState({internetConnectionLost: true});
    } else if (this.state.internetConnectionLost) {
      //connection restored, try reconnecting
      toast.success(this.state.message_online);
      this.setState({
        internetConnectionRestored: true,
        internetConnectionLost: false,
      });
      setTimeout(() => {
        if (!this.state.cancelAutoReconnect) {
          this.props.markMediaSelectionDone(this.state.message_reconnecting);
        }
      }, 4500);
    }
  };

  handleToastMessageClosed = () => {
    if (this.state.internetConnectionRestored) {
      this.setState({
        internetConnectionRestored: false,
        internetConnectionLost: false,
        cancelAutoReconnect: true,
      });
    }
  };

  getControls = () => (
    <div
      style={{
        display: 'inline-flex',
        width: '100%',
        margin: '0 auto',
      }}>
      <MediaControl
        meetingSessionIsActive={this.meetingSessionIsActive()}
        videoIsAvailable={this.videoIsAvailable()}
        screencastIsAvailable={this.screencastIsAvailable()}
        toggleMediaState={this.toggleMediaState}
        deviceType={this.props.deviceType}
        localVideoInputDevices={this.props.localVideoInputDevices}
        localAudioInputDevices={this.props.localAudioInputDevices}
        localAudioOutputDevices={this.props.localAudioOutputDevices}
        currentAudioInputDevice={this.props.currentAudioInputDevice}
        currentAudioOutputDevice={this.props.currentAudioOutputDevice}
        currentVideoDevice={this.props.currentVideoDevice}
        setCurrentAudioInputDeviceId={this.props.setCurrentAudioInputDeviceId}
        setCurrentAudioOutputDeviceId={this.props.setCurrentAudioOutputDeviceId}
        setCurrentVideoDeviceId={this.props.setCurrentVideoDeviceId}
        isFullScreenMode={this.props.isFullScreenMode}
        toggleFullScreenMode={this.props.toggleFullScreenMode}
      />

      {this.props.isFullScreenMode && <div id='popup-root' />}
    </div>
  );

  render() {
    this.mainVideo = createRef();
    if (this.props.mediaSelectionComplete) {
      return (
        <div className='container-fluid'>
          <ParticipantsList
            deviceType={this.props.deviceType}
            isMobile={this.props.isMobile}
            isVideoPaused={this.isVideoPaused}
            participants={this.props.participants}
            updateTrackRefs={this.props.updateTrackRefs}
            isLocalParticipant={this.props.isLocalParticipant}
            isActiveParticipant={this.props.isActiveParticipant}
            isActiveParticipantPinned={this.props.isActiveParticipantPinned}
            isActiveVideoDetached={this.props.isActiveVideoDetached}
            setActiveParticipant={this.props.setActiveParticipant}
            participantAudioTracks={this.props.participantAudioTracks}
          />
          <MainVideoContainer
            ref={this.mainVideo}
            deviceType={this.props.deviceType}
            isVideoPaused={this.isVideoPaused}
            isFullScreenMode={this.props.isFullScreenMode}
            activeParticipant={this.props.activeParticipant}
          />
          {this.props.isFullScreenMode ? (
            this.getControls()
          ) : (
            <Overlay target={this.mainVideo.current} show={true} placement='bottom'>
              {({...props}) => (
                <div
                  {...props}
                  style={{
                    padding: '2px 10px',
                    color: 'white',
                    borderRadius: 3,
                    marginTop: '-70px',
                    textAlign: 'center',
                    ...props.style,
                    ...this.getResponsiveOverrides(),
                  }}>
                  {this.getControls()}
                </div>
              )}
            </Overlay>
          )}

          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            onClose={this.handleToastMessageClosed}
            limit={2}
          />

          <NavigatorOnline
            onChange={(status) => this.handleInternetConnectionChange(status)}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

MeetingView.propTypes = {
  context: PropTypes.object.isRequired,
  participants: PropTypes.array.isRequired,
  activeParticipant: PropTypes.object.isRequired,
  mediaSelectionComplete: PropTypes.bool.isRequired,
  meetingIsLive: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isVideoPaused: PropTypes.func.isRequired,
  markMediaSelectionDone: PropTypes.func.isRequired,
  isFullScreenMode: PropTypes.bool.isRequired,
  toggleFullScreenMode: PropTypes.func.isRequired,
  localAudioInputDevices: PropTypes.array.isRequired,
  localAudioOutputDevices: PropTypes.array.isRequired,
  localVideoInputDevices: PropTypes.array.isRequired,
  currentAudioInputDevice: PropTypes.string,
  currentAudioOutputDevice: PropTypes.string,
  currentVideoDevice: PropTypes.string,
  setCurrentAudioInputDeviceId: PropTypes.func.isRequired,
  setCurrentAudioOutputDeviceId: PropTypes.func.isRequired,
  setCurrentVideoDeviceId: PropTypes.func.isRequired,
  updateTrackRefs: PropTypes.func.isRequired,
  isLocalParticipant: PropTypes.func.isRequired,
  isActiveParticipant: PropTypes.func.isRequired,
  isActiveParticipantPinned: PropTypes.func.isRequired,
  isActiveVideoDetached: PropTypes.func.isRequired,
  setActiveParticipant: PropTypes.func.isRequired,
  participantAudioTracks: PropTypes.array.isRequired,
};

export default inject('context')(observer(MeetingView));
