import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this._handleNameChange = this._handleNameChange.bind(this);
    this._handleEmailChange = this._handleEmailChange.bind(this);
  }

  _handleNameChange = (e) => {
    this.props.context.UI.setUserName(e.target.value);
  };

  _handleEmailChange = (e) => {
    this.props.context.UI.setUserEmail(e.target.value);
  };

  render() {
    return (
      <div>
        <div className='form-group'>
          <label id='screen-name-label' htmlFor='screen-name'>
            Full Name
          </label>
          <input
            id='screen-name'
            className='form-control'
            type='text'
            tabIndex='1'
            onBlur={this._handleNameChange}
          />
          <span
            style={{
              display: 'block',
              color: 'red',
              fontSize: 'small',
              marginBottom: '10px',
            }}>
            {this.props.context.UI.validationErrors['name']}
          </span>
          <label id='email-label' htmlFor='email'>
            Email
          </label>
          <input
            id='email'
            className='form-control'
            type='text'
            tabIndex='2'
            onChange={this._handleEmailChange}
          />
        </div>
      </div>
    );
  }
}

UserInfo.propTypes = {
  context: PropTypes.object,
};

export default inject('context')(observer(UserInfo));
