import React, {createRef} from 'react';
import {observer, inject} from 'mobx-react';
import {getOriginalName} from '../Helpers/nameHelper';
import PropTypes from 'prop-types';
import {MOBILE_OR_SIMILAR} from '../Constants/deviceTypes';
import {
  SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE,
  DISABLE_PINNING_PARTICIPANT_VIDEO,
} from '../Helpers/configHelper';
import ParticipantMediaIndicators from './participantMediaIndicators';
import {ACTIVE} from '../Constants/mediaTrackState';

const participantContainerStyle = {
  maxHeight: '160px',
  height: '110px',
  maxWidth: '160px',
};

const responsiveOverrides = {
  MOBILE_OR_SIMILAR: {
    maxHeight: '140px',
    height: '79px',
    maxWidth: '105px',
    marginTop: '25px',
  },
};

class Participant extends React.Component {
  constructor(props) {
    super(props);
    this.isLocalParticipant = this.isLocalParticipant.bind(this);
    this.isActiveParticipant = this.isActiveParticipant.bind(this);
    this.isActiveParticipantPinned = this.isActiveParticipantPinned.bind(this);
    this.isActiveVideoDetached = this.isActiveVideoDetached.bind(this);
    this.getParticipantContainerClass = this.getParticipantContainerClass.bind(this);
    this.updateTrackRefs = this.updateTrackRefs.bind(this);
    this.setActiveParticipant = this.setActiveParticipant.bind(this);
    this.getResponsiveOverrides = this.getResponsiveOverrides.bind(this);
    this.activeVideoIsParticipants = this.activeVideoIsParticipants.bind(this);
    this.hasAudio = this.hasAudio.bind(this);
    this.audio = createRef();
    this.video = createRef();
  }

  UNSAFE_componentWillMount() {
    this.updateTrackRefs();
  }

  componentDidMount() {
    this.updateTrackRefs();
  }

  componentDidUpdate() {
    this.updateTrackRefs();
  }

  updateTrackRefs = () => {
    this.props.updateTrackRefs(
      this.props.participant.sid,
      this.audio.current,
      this.video.current
    );
  };

  isLocalParticipant = () => this.props.isLocalParticipant(this.props.participant);
  isActiveParticipant = () => this.props.isActiveParticipant(this.props.participant);
  isActiveParticipantPinned = () => this.props.isActiveParticipantPinned();
  isActiveVideoDetached = () =>
    this.props.isActiveVideoDetached(this.props.participant.sid);

  activeVideoIsParticipants = () => {
    return Array.from(this.props.participant.videoTracks.values()).some((videoTrack) => {
      const {track: activeTrack} = videoTrack;
      return (
        !!activeTrack &&
        activeTrack.id === this.props.context.Conference.eventData.activeVideoTrack?.id
      );
    });
  };

  getParticipantContainerClass = () => {
    return `participant 
        ${
          this.isActiveParticipant() || this.activeVideoIsParticipants()
            ? 'active'
            : !DISABLE_PINNING_PARTICIPANT_VIDEO && this.isActiveParticipantPinned()
            ? 'pinned'
            : ''
        }`;
  };

  setActiveParticipant = () => {
    this.props.setActiveParticipant(this.props.participant);
  };

  getResponsiveOverrides = () => {
    if (
      this.props.deviceType === MOBILE_OR_SIMILAR &&
      SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE
    ) {
      return responsiveOverrides[this.props.deviceType];
    }

    return {};
  };

  hasAudio = () => {
    var tracks = this.props.participantAudioTracks.filter(
      (track) => track.participant === this.props.participant.sid
    );
    return tracks.some((track) => track.state === ACTIVE);
  };

  render() {
    const {identity, sid} = this.props.participant;
    var containerClass = this.getParticipantContainerClass();
    return (
      <>
        <div
          className={containerClass}
          style={{...participantContainerStyle, ...this.getResponsiveOverrides()}}
          data-identity={getOriginalName(identity)}
          id={sid}
          key={this.props.key}
          onClick={this.setActiveParticipant}>
          {this.isLocalParticipant() && (
            <audio ref={this.audio} style={{opacity: '0'}} autoPlay muted></audio>
          )}
          {!this.isLocalParticipant() && (
            <audio ref={this.audio} style={{opacity: '0'}} autoPlay></audio>
          )}
          {!this.props.isVideoPaused(sid) && (
            <video
              ref={this.video}
              style={{opacity: this.isActiveVideoDetached() ? '0' : ''}}
              autoPlay
              muted
              playsInline></video>
          )}
          {this.props.isVideoPaused(sid) && (
            <video
              style={{opacity: '0', backgroundColor: 'black'}}
              autoPlay
              muted
              playsInline></video>
          )}
          <ParticipantMediaIndicators hasAudio={this.hasAudio()} />
        </div>
      </>
    );
  }
}

Participant.propTypes = {
  participant: PropTypes.object.isRequired,
  key: PropTypes.number.isRequired,
  context: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  setActiveParticipant: PropTypes.func.isRequired,
  isVideoPaused: PropTypes.func.isRequired,
  updateTrackRefs: PropTypes.func.isRequired,
  isLocalParticipant: PropTypes.func.isRequired,
  isActiveParticipant: PropTypes.func.isRequired,
  isActiveParticipantPinned: PropTypes.func.isRequired,
  isActiveVideoDetached: PropTypes.func.isRequired,
  participantAudioTracks: PropTypes.array.isRequired,
};

export default inject('context')(observer(Participant));
