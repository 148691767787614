import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {useFullScreenHandle} from 'react-full-screen';

const FullScreenContext = (props) => {
  const fullScreenHandle = useFullScreenHandle();

  return React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      fullScreen: fullScreenHandle,
    });
  });
};

FullScreenContext.propTypes = {
  children: PropTypes.object.isRequired,
};

export default inject('context')(observer(FullScreenContext));
