import React from 'react';
import {ERROR} from '../Constants/workflowStatus';
import PropTypes from 'prop-types';

class ErrorMessage extends React.Component {
  constructor(props) {
    super(props);
    this.show = this.show.bind(this);
  }

  show = () => {
    if (this.props.show) {
      return this.props.show();
    }

    return false;
  };

  render() {
    var error =
      this.show() && this.props.message
        ? this.props.message
        : this.props.status === ERROR && this.props.lastErrorMessage
        ? this.props.lastErrorMessage
        : null;
    var styles = this.props.customStyle || {textAlign: 'center'};
    if (error)
      return (
        <div style={styles}>
          <p className='red alert alert-warning' role='alert'>
            {error}
          </p>
        </div>
      );
    else return null;
  }
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
  lastErrorMessage: PropTypes.string,
  show: PropTypes.func,
  status: PropTypes.string,
  customStyle: PropTypes.object,
};

export default ErrorMessage;
