import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import {AUDIO, VIDEO, SCREENCAST} from '../Constants/mediaType';
import Overlay from 'react-bootstrap/Overlay';
import {Button} from 'react-bootstrap';
import AudioDevicesSelect from './PopupMenus/audioDevicesSelect';
import VideoDevicesSelect from './PopupMenus/videoDevicesSelect';
import FullScreenControl from './fullScreenControl';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import VideocamOffRoundedIcon from '@mui/icons-material/VideocamOffRounded';
import ScreenShareRoundedIcon from '@mui/icons-material/ScreenShareRounded';
import StopScreenShareRoundedIcon from '@mui/icons-material/StopScreenShareRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import {ON, OFF} from '../Constants/mediaControlState';
import {lightGreen, red, blue} from '@mui/material/colors';
import {
  MEDIA_CONTROL_TURN_CAM_ON,
  MEDIA_CONTROL_TURN_CAM_OFF,
  MEDIA_CONTROL_TURN_MIC_ON,
  MEDIA_CONTROL_TURN_MIC_OFF,
  MEDIA_CONTROL_SCREENSHARE_ON,
  MEDIA_CONTROL_SCREENSHARE_OFF,
  MEDIA_CONTROL_EXIT_MEETING,
} from '../Constants/textResources';

const btnStyle = {
  background: 'none',
  verticalAlign: 'text-top',
};

const iconStyle = {
  background: 'black',
  fontSize: '3rem',
};

class MediaControl extends React.Component {
  constructor(props) {
    super(props);

    this.getAudioControlElement = this.getAudioControlElement.bind(this);
    this.getVideoControlElement = this.getVideoControlElement.bind(this);
    this.getFullScreenControl = this.getFullScreenControl.bind(this);
    this.getScreencastElement = this.getScreencastElement.bind(this);
    this.getAudioDevicesListElement = this.getAudioDevicesListElement.bind(this);
    this.getVideoDevicesListElement = this.getVideoDevicesListElement.bind(this);
    this.onAudioInputDeviceChangedHandler =
      this.onAudioInputDeviceChangedHandler.bind(this);
    this.onAudioOutputDeviceChangedHandler =
      this.onAudioOutputDeviceChangedHandler.bind(this);
    this.onVideoDeviceChangedHandler = this.onVideoDeviceChangedHandler.bind(this);
    this.exitBtnRef = createRef();
    this.mediaControlRef = createRef();
    this.state = {
      redirect: null,
      showExitConfirmation: false,
    };
  }

  getAudioControlElement = () => (
    <div className=''>
      <button
        id='btn-mic-on-off'
        onClick={() => this.props.toggleMediaState(AUDIO)}
        title={
          this.props.context.Conference.eventData.mediaControlState[AUDIO] === ON
            ? MEDIA_CONTROL_TURN_MIC_OFF
            : MEDIA_CONTROL_TURN_MIC_ON
        }
        style={btnStyle}>
        {this.props.context.Conference.eventData.mediaControlState[AUDIO] === ON && (
          <MicRoundedIcon sx={{...iconStyle, ...{color: lightGreen[600]}}} />
        )}
        {this.props.context.Conference.eventData.mediaControlState[AUDIO] === OFF && (
          <MicOffRoundedIcon sx={{...iconStyle, ...{color: red[600]}}} />
        )}
      </button>
    </div>
  );

  onAudioInputDeviceChangedHandler = (deviceId) => {
    this.props.setCurrentAudioInputDeviceId(deviceId);
  };

  onAudioOutputDeviceChangedHandler = (deviceId) => {
    this.props.setCurrentAudioOutputDeviceId(deviceId);
  };

  onVideoDeviceChangedHandler = (deviceId) => {
    this.props.setCurrentVideoDeviceId(deviceId);
  };

  getAudioDevicesListElement = () => (
    <AudioDevicesSelect
      audioInputDevices={this.props.localAudioInputDevices}
      audioOutputDevices={this.props.localAudioOutputDevices}
      currentAudioInputDevice={this.props.currentAudioInputDevice}
      currentAudioOutputDevice={this.props.currentAudioOutputDevice}
      onInputDeviceClicked={this.onAudioInputDeviceChangedHandler}
      onOutputDeviceClicked={this.onAudioOutputDeviceChangedHandler}
      isFullScreenMode={this.props.isFullScreenMode}
      mediaControlContainerRef={this.mediaControlRef}
    />
  );

  getVideoControlElement = () => (
    <div className=''>
      <button
        id='btn-camera-on-off'
        className={this.props.videoIsAvailable ? '' : 'hide'}
        style={btnStyle}
        title={
          this.props.context.Conference.eventData.mediaControlState[VIDEO] === ON
            ? MEDIA_CONTROL_TURN_CAM_OFF
            : MEDIA_CONTROL_TURN_CAM_ON
        }
        onClick={() => this.props.toggleMediaState(VIDEO)}>
        {this.props.context.Conference.eventData.mediaControlState[VIDEO] === ON && (
          <VideocamRoundedIcon sx={{...iconStyle, ...{color: lightGreen[600]}}} />
        )}
        {this.props.context.Conference.eventData.mediaControlState[VIDEO] === OFF && (
          <VideocamOffRoundedIcon sx={{...iconStyle, ...{color: red[600]}}} />
        )}
      </button>
    </div>
  );

  getVideoDevicesListElement = () => (
    <VideoDevicesSelect
      videoDevices={this.props.localVideoInputDevices}
      onClick={this.onVideoDeviceChangedHandler}
      currentVideoDevice={this.props.currentVideoDevice}
      isFullScreenMode={this.props.isFullScreenMode}
      mediaControlContainerRef={this.mediaControlRef}
    />
  );

  getScreencastElement = () => (
    <div className=''>
      <button
        id='btn-screenshare-on-off'
        className={this.props.screencastIsAvailable ? '' : 'hide'}
        style={btnStyle}
        title={
          this.props.context.Conference.eventData.mediaControlState[SCREENCAST] === ON
            ? MEDIA_CONTROL_SCREENSHARE_OFF
            : MEDIA_CONTROL_SCREENSHARE_ON
        }
        onClick={() => this.props.toggleMediaState(SCREENCAST)}>
        {this.props.context.Conference.eventData.mediaControlState[SCREENCAST] === ON && (
          <StopScreenShareRoundedIcon sx={{...iconStyle, ...{color: red[600]}}} />
        )}
        {this.props.context.Conference.eventData.mediaControlState[SCREENCAST] ===
          OFF && <ScreenShareRoundedIcon sx={{...iconStyle, ...{color: blue[600]}}} />}
      </button>
    </div>
  );

  handleMeetingExitBtnClick = () => {
    this.setState({showExitConfirmation: true});

    setTimeout(() => {
      this.setState({showExitConfirmation: false});
    }, 5000);
  };

  getLeaveMeetingBtn = () => {
    return (
      <div className='' id='collapsible-content'>
        <button
          id='btn-leave-room'
          className={this.props.context.UI.sessionIsValid() ? 'btn' : 'hide'}
          ref={this.exitBtnRef}
          onClick={this.handleMeetingExitBtnClick}
          title={MEDIA_CONTROL_EXIT_MEETING}
          style={{
            verticalAlign: 'text-bottom',
            marginTop: '-6px',
            marginLeft: '-6px',
            ...btnStyle,
          }}>
          <PowerSettingsNewRoundedIcon sx={{...iconStyle, ...{color: red[700]}}} />
        </button>
        <Overlay
          target={this.exitBtnRef.current}
          placement='top'
          show={this.state.showExitConfirmation}>
          {({...props}) => (
            <div
              {...props}
              style={{
                padding: '2px 10px',
                marginLeft: '-25px',
                color: 'white',
                borderRadius: 3,
                marginTop: '-70px',
                textAlign: 'center',
                ...props.style,
              }}>
              <Button
                onClick={this.endMeeting}
                style={{color: 'white', background: 'red'}}>
                Exit Meeting
              </Button>
            </div>
          )}
        </Overlay>
      </div>
    );
  };

  getFullScreenControl = () => (
    <FullScreenControl
      toggleFullScreenMode={this.props.toggleFullScreenMode}
      isFullScreenMode={this.props.isFullScreenMode}
    />
  );

  endMeeting = () => {
    this.props.context.UI.endCurrentMeeting();
    this.redirectToHome();
  };

  redirectToHome = () => {
    this.setState({redirect: '/'});
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
  };

  render() {
    let audioControl,
      audioDevicesSelect,
      videoControl,
      videoDevicesSelect,
      screencastControl,
      leaveMeetingBtn,
      fullScreenControl;

    audioControl = this.getAudioControlElement();
    if (
      (this.props.localAudioInputDevices && this.props.localAudioInputDevices.length) ||
      (this.props.localAudioOutputDevices && this.props.localAudioOutputDevices.length)
    ) {
      audioDevicesSelect = this.getAudioDevicesListElement();
    }

    if (this.props.videoIsAvailable) {
      videoControl = this.getVideoControlElement();
      if (
        this.props.localVideoInputDevices &&
        this.props.localVideoInputDevices.length > 1
      ) {
        videoDevicesSelect = this.getVideoDevicesListElement();
      }
    }

    fullScreenControl = this.getFullScreenControl();

    if (this.props.screencastIsAvailable) screencastControl = this.getScreencastElement();

    if (this.props.meetingSessionIsActive) leaveMeetingBtn = this.getLeaveMeetingBtn();

    return (
      <>
        {this.renderRedirect()}
        <div
          className='row'
          id='container-media-control'
          style={{
            margin: '0 auto',
            background: 'black',
            borderRadius: 3,
          }}
          ref={this.mediaControlRef}>
          <div style={{display: 'inline-flex', margin: '0 auto'}}>
            {audioControl}
            {audioDevicesSelect}
            {videoControl}
            {videoDevicesSelect}
            {screencastControl}
            {leaveMeetingBtn}
            {fullScreenControl}
          </div>
        </div>
      </>
    );
  }
}

MediaControl.propTypes = {
  context: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  videoIsAvailable: PropTypes.bool.isRequired,
  toggleMediaState: PropTypes.func.isRequired,
  screencastIsAvailable: PropTypes.bool.isRequired,
  meetingSessionIsActive: PropTypes.bool.isRequired,
  localAudioInputDevices: PropTypes.array.isRequired,
  localAudioOutputDevices: PropTypes.array.isRequired,
  localVideoInputDevices: PropTypes.array.isRequired,
  currentAudioInputDevice: PropTypes.string,
  currentAudioOutputDevice: PropTypes.string,
  currentVideoDevice: PropTypes.string,
  setCurrentAudioInputDeviceId: PropTypes.func.isRequired,
  setCurrentAudioOutputDeviceId: PropTypes.func.isRequired,
  setCurrentVideoDeviceId: PropTypes.func.isRequired,
  isFullScreenMode: PropTypes.bool.isRequired,
  toggleFullScreenMode: PropTypes.func.isRequired,
};

export default inject('context')(observer(MediaControl));
