import React from 'react';
import PropTypes from 'prop-types';
import Nav from '../Components/nav';
import ErrorMessage from '../Components/errorMessage';
import {inject, observer} from 'mobx-react';

class ErrorPage extends React.Component {
  render() {
    return (
      <>
        <Nav {...this.props} />
        <ErrorMessage
          status={this.props.status}
          lastErrorMessage={this.props.lastErrorMessage}
        />
        {/* Send link to Welcome Page */}
      </>
    );
  }
}

ErrorPage.propTypes = {
  status: PropTypes.string,
  lastErrorMessage: PropTypes.string,
};

export default inject('context')(observer(ErrorPage));
