import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import QUERIES from '../Constants/mediaQueries';
import {observer, inject} from 'mobx-react';
import {
  DESKTOP_OR_LAPTOP,
  IPAD_OR_TABLET,
  MOBILE_OR_SIMILAR,
  DEFAULT,
} from '../Constants/deviceTypes';

class ResponsiveContext extends React.Component {
  constructor(props) {
    super(props);
    this.getChildrenWithinjectDeviceTypeProp =
      this.getChildrenWithinjectDeviceTypeProp.bind(this);
  }

  getChildrenWithinjectDeviceTypeProp = (deviceType) => {
    const childrenWithExtraProp = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        deviceType: deviceType,
      });
    });

    return childrenWithExtraProp;
  };

  render() {
    return (
      <Media queries={QUERIES}>
        {(matches) => {
          var deviceType = matches.DesktopOrLaptop
            ? DESKTOP_OR_LAPTOP
            : matches.iPadOrTablet
            ? IPAD_OR_TABLET
            : matches.mobile
            ? MOBILE_OR_SIMILAR
            : DEFAULT;
          return this.getChildrenWithinjectDeviceTypeProp(deviceType);
        }}
      </Media>
    );
  }
}

ResponsiveContext.propTypes = {
  children: PropTypes.object.isRequired,
};

export default inject('context')(observer(ResponsiveContext));
