import {observable, runInAction, makeAutoObservable} from 'mobx';
import RelayService from '../Services/relayService';
import {CoreConferenceEngine} from 'twilio-conferencing-core-engine';
import {uniquifyName} from '../Helpers/nameHelper';
import {TERMINATED_BY_HOST} from '../Constants/routeParams';
import {FRIENDLIFY_ERROR_MESSAGES} from '../Helpers/configHelper';
import {
  INITIAL,
  SUCCESS,
  PENDING,
  ERROR,
  WAITING_FOR_HOST,
  MEETING_PREP,
  MEETING_INVALIDATED,
} from '../Constants/workflowStatus';
import {FRIENDLY_MESSAGES} from '../Constants/friendlyErrorMessages';
import {debug} from '../Helpers/errorHelper';
import {INVALID_MEETING_CODE} from '../Constants/textResources';
import {MEETING_ENDED} from '../Constants/textResources';

const {
  listAllAudioInputDevices,
  listAllAudioOutputDevices,
  listAllVideoDevices,
  onMediaDevicesListChange,
  assignDefaultAudioInputDeviceId,
  assignDefaultAudioOutputDeviceId,
  assignDefaultVideoInputDeviceId,
} = CoreConferenceEngine;

export default class UIStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.relayService = new RelayService();
    onMediaDevicesListChange(this.populateAudioVideoDevices);
    makeAutoObservable(this);
  }

  init = async () => {
    return new Promise((resolve, error) => {
      this.relayService
        .initialize()
        .then(() => {
          this.relayData.apiAccessTokenSet = true;
          resolve();
        })
        .catch((e) => {
          this.status = ERROR;
          this.lastErrorMessage = e.message;
          if (error) {
            error();
          }
        });
    });
  };

  initRelayData = () => {
    return {
      micLevel: 0,
      logoUrl: 'https://is.ascendproject.com/img/logo.png',
      conferenceShortId: null,
      currentRoomUniqueName: '',
      userName: null,
      userEmail: null,
      isAgent: false,
      uniqueUserName: null,
      apiAccessTokenSet: false,
      userAccessToken: null,
      audioDevicesLoadComplete: false,
      videoDevicesLoadComplete: false,
      mediaSelectionComplete: false,
      meetingPrepComplete: false,
      localAudioInputDevices: observable([]),
      localAudioOutputDevices: observable([]),
      localVideoInputDevices: observable([]),
      skipMeetingCodeValidation: false,
      joinMeetingWithCameraTurnedOff: false,
      joinMeetingWithMicMuted: false,
    };
  };

  relayData = this.initRelayData();

  status = INITIAL;
  lastErrorMessage = null;
  validationErrors = {};

  validateFields = () => {
    runInAction(() => {
      if (!this.relayData.userName || this.relayData.userName.trim() === '') {
        this.validationErrors.name = 'Please enter a valid name';
      }
    });
  };

  setUserName = (userName, skipValidation = false) => {
    runInAction(() => {
      if (skipValidation) {
        delete this.validationErrors.name;
      }

      this.relayData.userName = userName;

      if (!skipValidation) this.validateFields();

      this.relayData.uniqueUserName = uniquifyName(userName);
    });
  };

  setUserEmail = (userEmail) => {
    runInAction(() => {
      this.relayData.userEmail = userEmail;
    });
  };

  setUserType = (routePath) => {
    runInAction(() => {
      this.relayData.isAgent = routePath && routePath.includes('agent');
    });
  };

  setJoinMeetingWithCameraTurnedOff = (val) => {
    runInAction(() => {
      this.relayData.joinMeetingWithCameraTurnedOff = val;
    });
  };

  setJoinMeetingWithMicMuted = (val) => {
    runInAction(() => {
      this.relayData.joinMeetingWithMicMuted = val;
    });
  };

  hasValidationErrors = () => {
    return this.validationErrors.name || this.relayData.userName === '';
  };

  populateAudioVideoDevices = async () => {
    return new Promise((resolve) => {
      runInAction(async () => {
        var audioInputDevices = await listAllAudioInputDevices();
        var audioOutputDevices = await listAllAudioOutputDevices();
        debug(audioInputDevices);
        debug(audioOutputDevices);

        var videoDevices = await listAllVideoDevices();
        if (audioInputDevices && audioInputDevices.length) {
          this.relayData.localAudioInputDevices.replace(audioInputDevices);

          if (!this.rootStore.Conference.eventData.audioInputDeviceId.value) {
            this.rootStore.Conference.eventData.audioInputDeviceId.set(
              audioInputDevices[0].deviceId
            );

            assignDefaultAudioInputDeviceId(audioInputDevices[0].deviceId);
          }
        }

        if (audioOutputDevices && audioOutputDevices.length) {
          this.relayData.localAudioOutputDevices.replace(audioOutputDevices);
          if (!this.rootStore.Conference.eventData.audioOutputDeviceId.value) {
            this.rootStore.Conference.eventData.audioOutputDeviceId.set(
              audioOutputDevices[0].deviceId
            );

            assignDefaultAudioOutputDeviceId(audioOutputDevices[0].deviceId);
          }
        }

        if (videoDevices && videoDevices.length) {
          this.relayData.localVideoInputDevices.replace(videoDevices);
          if (!this.rootStore.Conference.eventData.videoDeviceId.get()) {
            this.rootStore.Conference.eventData.videoDeviceId.set(
              videoDevices[0].deviceId
            );

            assignDefaultVideoInputDeviceId(videoDevices[0].deviceId);
          }
        }

        debug(this.relayData.localAudioInputDevices);
        debug(this.relayData.localAudioOutputDevices);

        this.relayData.audioDevicesLoadComplete = true;
        this.relayData.videoDevicesLoadComplete = true;

        resolve();
      });
    });
  };

  devicesPopulated = () => {
    return (
      this.relayData.audioDevicesLoadComplete && this.relayData.videoDevicesLoadComplete
    );
  };

  hasAudioOrVideoDevices = () => {
    return (
      this.relayData.localAudioInputDevices.length ||
      this.relayData.localVideoInputDevices.length
    );
  };

  sessionIsValid = () => {
    return this.relayData.conferenceShortId !== null;
  };

  resumeMeetingForGuest = () => {
    return new Promise((resolve, error) => {
      this.getUserToken()
        .then(() => {
          this.status = SUCCESS;
          resolve();
        })
        .catch(error);
    });
  };

  setupMeetingForGuest = (resolve, error, meetingPrerequisitesSetup) => {
    this.getMeetingSessionDetails()
      .then((result) => {
        if (result.isSuccess && result.isLive) {
          this.getUserToken()
            .then(() => {
              this.status = SUCCESS;
              resolve({
                showMeetingView: meetingPrerequisitesSetup && result.isSuccess,
              });
            })
            .catch(error);
        } else if (!result.isLive) {
          resolve({
            showMeetingView: false,
            waitingForAgent: result.waitingForAgent,
            showMessage: result.waitingForAgentMessage,
          });
        }
      })
      .catch(error);
  };

  setupMeetingForHost = (resolve, error, meetingPrerequisitesSetup) => {
    this.prepForNewMeetingSession()
      .then((result) => {
        if (result.isSuccess) {
          this.getUserToken()
            .then(() => {
              this.status = SUCCESS;
              resolve({
                showMeetingView: meetingPrerequisitesSetup && result.isSuccess,
              });
            })
            .catch(error);
        }
      })
      .catch(error);
  };

  markMediaSelectionComplete = () => {
    return new Promise((resolve, error) => {
      runInAction(() => {
        this.relayData.mediaSelectionComplete = true;
        var meetingPrerequisitesSetup =
          this.rootStore.Conference.setupMeetingPrerequisites();
        this.status = MEETING_PREP;

        if (this.relayData.isAgent) {
          this.setupMeetingForHost(resolve, error, meetingPrerequisitesSetup);
        } else {
          this.setupMeetingForGuest(resolve, error, meetingPrerequisitesSetup);
        }
      });
    });
  };

  isMediaSelectionComplete = () => this.relayData.mediaSelectionComplete;

  validateMeetingCode = async (meetingCode) => {
    runInAction(() => {
      this.status = PENDING;
    });

    return new Promise((resolve, error) => {
      if (meetingCode.length !== 22) {
        //meeting code did not pass length check
        runInAction(() => {
          this.status = MEETING_INVALIDATED;
          this.lastErrorMessage = INVALID_MEETING_CODE;
        });
        resolve(false);
        return;
      }
      this.relayService
        .getConferenceDetails(meetingCode, this.relayData.isAgent)
        .then((response) => {
          if (!response) {
            //did not get a response back from API
            runInAction(() => {
              this.status = MEETING_INVALIDATED;
              this.lastErrorMessage =
                'Sorry, we are facing technical issues right now. Please try again later!';
            });
            resolve(false);
          }

          runInAction(() => {
            this.relayData.conferenceShortId = meetingCode;
            this.relayData.currentRoomUniqueName = response.roomName;
            this.relayData.beId = response.beId;
            this.relayData.userName = this.setUserName(
              this.relayData.isAgent ? response.agentFullName : response.guestFullName,
              true
            );
          });
          if (!response.isValid && !response.isValidMeetingCode) {
            //got a response but did not find the meeting code
            runInAction(() => {
              this.status = MEETING_INVALIDATED;
              this.lastErrorMessage = INVALID_MEETING_CODE;
            });

            resolve(false);
          } else if (
            !this.relayData.isAgent &&
            (response.meetingHasEnded || response.meetingEndedAfterIdle) &&
            !response.hasValidOngoingActiveSession
          ) {
            runInAction(() => {
              this.status = MEETING_INVALIDATED;
              this.lastErrorMessage = MEETING_ENDED;
            });

            resolve(false);
          } else {
            var status = '';
            //response and meeting code are valid
            if (response.isValid) {
              status = SUCCESS;
            }
            //check if agent has not joined yet
            //guest is waiting for agent to join
            else if (!this.relayData.isAgent && !response.agentHasJoined) {
              status = WAITING_FOR_HOST;
            }
            runInAction(() => {
              this.status = status;
              this.lastErrorMessage = response.validationResults[0];
            });

            resolve(true);
          }
        })
        .catch((e) => {
          runInAction(() => {
            this.status = ERROR;
            this.lastErrorMessage = `Failed to fetch conference details`;
          });
          error(e);
        });
    });
  };

  reportError = (error) => {
    runInAction(() => {
      this.status = ERROR;
      this.lastErrorMessage = this.friendlifyError(error.message || error.name);
    });
  };

  friendlifyError = (errorMessage) => {
    if (!FRIENDLIFY_ERROR_MESSAGES) {
      return errorMessage;
    }

    if (!errorMessage) {
      return FRIENDLY_MESSAGES['default'];
    }

    return FRIENDLY_MESSAGES[errorMessage.toString().toLowerCase()] || errorMessage;
  };

  getUserToken = async () => {
    return new Promise((resolve, error) => {
      this.relayService
        .getUserToken(
          this.relayData.beId,
          this.relayData.uniqueUserName,
          this.relayData.currentRoomUniqueName
        )
        .then((response) => {
          if (response) {
            if (response.isValid) {
              runInAction(() => {
                debug('User access token ', response);
                this.relayData.userAccessToken = response.token;
              });

              if (resolve) resolve();
            } else {
              runInAction(() => {
                this.status = ERROR;
                this.lastErrorMessage = `Failed to fetch user token`;
              });
            }
          }
        })
        .catch((e) => {
          runInAction(() => {
            this.status = ERROR;
            this.lastErrorMessage = `Failed to fetch user token`;
          });

          if (error) error(e);
        });
    });
  };

  getMeetingSessionDetails = async () => {
    return new Promise((resolve, error) => {
      this.relayService
        .getMeetingSessionDetails(this.relayData.conferenceShortId)
        .then((response) => {
          if (response) {
            runInAction(() => {
              this.relayData.meetingPrepComplete = response.isValid && response.isLive;
            });

            var meetingSessionStatus = {
              isSuccess: response.isValid,
              isLive: response.isLive,
              waitingForAgent: !response.isLive,
              waitingForAgentMessage: !response.isLive && response.validationResults[0],
            };

            resolve(meetingSessionStatus);
          }
        })
        .catch((e) => {
          runInAction(() => {
            this.status = ERROR;
            this.lastErrorMessage = `Get meeting session details failed- ${e}`;
          });

          error(e);
        });
    });
  };

  prepForNewMeetingSession = async () => {
    return new Promise((resolve, error) => {
      this.relayService
        .prepMeetingSession(this.relayData.conferenceShortId)
        .then((response) => {
          if (response) {
            runInAction(() => {
              this.relayData.meetingPrepComplete = response.isValid;
            });

            var meetingSessionStatus = {
              isSuccess: response.isValid,
            };

            resolve(meetingSessionStatus);
          }
        })
        .catch((e) => {
          runInAction(() => {
            this.status = ERROR;
            this.lastErrorMessage = `Get meeting session details failed- ${e}`;
          });

          error(e);
        });
    });
  };

  resumeAllAwaitingGuests = () => {
    return new Promise((resolve, error) => {
      this.relayService
        .resumeAllAwaitingGuests(this.relayData.conferenceShortId)
        .then(resolve)
        .catch(error);
    });
  };

  resetData = () => {
    runInAction(() => {
      this.relayData = this.initRelayData();
      this.status = INITIAL;
      this.lastErrorMessage = null;
    });
  };

  endCurrentMeeting = async () => {
    await this.rootStore.Conference.endMeeting();
    this.resetData();
  };

  endCurrentMeetingAndRedirectToWelcomePage = async (initiatedByRemoteHost = false) => {
    if (initiatedByRemoteHost) {
      window.location = window.location.origin + '/' + TERMINATED_BY_HOST;
    }
    await this.endCurrentMeeting();
  };
}
