import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ErrorMessage from '../errorMessage';

const InnerModal = ({
  index,
  actions,
  handleClose,
  currentlyVisibleModal,
  numOfModals,
  isSelectionComplete,
  children,
  headerTitle,
  gotoPrevious,
  gotoNext,
  isLastInQueue,
  hidePreviousBtn,
  skipValidation,
}) => {
  var previousClicked = () => {
    gotoPrevious && gotoPrevious(index);
  };

  var nextClicked = () => {
    gotoNext && gotoNext(index, numOfModals, skipValidation);
  };

  var closeModal = () => {
    handleClose && handleClose();
  };

  return ReactDOM.createPortal(
    <Modal
      actions={actions}
      show={!isSelectionComplete() && currentlyVisibleModal === index}
      backdrop='static'
      keyboard={false}
      centered={true}
      animation={true}
      autoFocus={true}
      onExit={closeModal}>
      <Modal.Header>
        <Modal.Title>{headerTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        <ErrorMessage />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={previousClicked}
          style={{display: hidePreviousBtn === true ? 'none' : ''}}>
          Previous
        </Button>
        <Button variant='primary' onClick={nextClicked}>
          {!isLastInQueue(index, numOfModals) ? 'Next' : 'Join'}
        </Button>
      </Modal.Footer>
    </Modal>,
    document.getElementById('modal')
  );
};

export default InnerModal;
