import {runInAction, makeAutoObservable} from 'mobx';
import {
  RELAY_HUB_URL,
  REALTIME_INTERVAL_BETWEEN_RECONNECT_SECONDS,
} from '../Helpers/configHelper';
import {getFormattedBearerToken} from '../Helpers/tokenHelper';
import {debug, handleError} from '../Helpers/errorHelper';
const signalR = require('@microsoft/signalr');

export default class RealTimeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.relayService = rootStore.UI.relayService;
    makeAutoObservable(this);
  }

  //keep realtime connection state
  connectionState = {
    lastActiveConnectionId: null,
    hostIsReady: false,
    reconnectAttempts: 0,
  };

  getReconnectAttempts = () => this.connectionState.reconnectAttempts;

  isAgent = () => {
    return this.rootStore.UI.relayData.isAgent;
  };

  getConferenceShortId = () => {
    return this.rootStore.UI.relayData.conferenceShortId;
  };

  conferenceRequestIsValid = (conferenceRequestShortId) => {
    return this.getConferenceShortId() === conferenceRequestShortId;
  };

  handleHostJoinedMeeting = (conferenceRequestShortId) => {
    if (this.conferenceRequestIsValid(conferenceRequestShortId)) {
      runInAction(() => {
        this.connectionState.hostIsReady = true;
      });
    }
  };

  handleHostPausedVideo = (conferenceRequestShortId, participantId) => {
    debug('handleHostPausedVideo');
    if (this.conferenceRequestIsValid(conferenceRequestShortId)) {
      this.rootStore.Conference.setRemoteParticipantVideoStateToPaused(participantId);
    }
  };

  handleHostResumedVideo = (conferenceRequestShortId, participantId) => {
    debug('handleHostResumedVideo');
    if (this.conferenceRequestIsValid(conferenceRequestShortId)) {
      this.rootStore.Conference.setRemoteParticipantVideoStateToResumed(participantId);
    }
  };

  handleHostTerminatedMeeting = (conferenceRequestShortId) => {
    debug('handleHostTerminatedMeeting');
    if (this.conferenceRequestIsValid(conferenceRequestShortId)) {
      this.rootStore.Conference.terminateCurrentMeeting(true);
    }
  };

  resetConnectionRetryAttemptCounter = () => {
    runInAction(() => {
      this.connectionState.reconnectAttempts = 0;
    });
  };

  increaseConnectionRetryAttemptCounter = () => {
    runInAction(() => {
      this.connectionState.reconnectAttempts = this.connectionState.reconnectAttempts + 1;
    });
  };

  reportMaxRetryAttemptsReachedError = () => {
    this.rootStore.UI.reportError(
      new Error(
        'Sorry, we are unable to connect to the host at the moment. Please try refreshing the page!'
      )
    );
  };

  saveConnectionId = (connectionId) => {
    runInAction(() => {
      this.connectionState.lastActiveConnectionId = connectionId;
    });
  };

  getLastActiveConnectionId = () => {
    return this.connectionState.lastActiveConnectionId;
  };

  registerConnection = () => {
    var conferenceShortId = this.getConferenceShortId();
    var isAgent = this.isAgent();
    var connectionId = this.getLastActiveConnectionId();

    if (!isAgent) {
      this.relayService.registerGuestConnection(conferenceShortId, connectionId);
      debug(
        'Registering connectionId:' +
          connectionId +
          'for meeting code:' +
          conferenceShortId
      );
    }
  };

  initConnection = (maxRetryAttempts) => {
    var _self = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(RELAY_HUB_URL, {
        accessTokenFactory: getFormattedBearerToken,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    function start(isReconnect) {
      if (connection.connectionState === 'Connected') {
        return;
      }

      connection
        .start()
        .then(() => {
          debug('SignalR Connected.');

          _self.saveConnectionId(connection.connectionId);

          _self.registerConnection();

          _self.resetConnectionRetryAttemptCounter();
        })
        .catch((e) => {
          handleError(e, e.stack, _self.rootStore);
          if (_self.getReconnectAttempts() <= maxRetryAttempts) {
            setTimeout(() => {
              start(true);
            }, REALTIME_INTERVAL_BETWEEN_RECONNECT_SECONDS * 1000);
          } else {
            _self.reportMaxRetryAttemptsReachedError();
          }

          if (isReconnect) {
            _self.increaseConnectionRetryAttemptCounter();
          }
        });
    }

    connection.onclose(() => {
      if (!_self.isAgent()) {
        _self.relayService.expungeGuestConnection(
          _self.getConferenceShortId(),
          _self.getLastActiveConnectionId()
        );
      }

      start(true);
    });

    connection.on('HostJoinedMeeting', _self.handleHostJoinedMeeting);
    connection.on('HostPausedVideo', _self.handleHostPausedVideo);
    connection.on('HostResumedVideo', _self.handleHostResumedVideo);
    connection.on('HostTerminatedMeeting', _self.handleHostTerminatedMeeting);

    // Start the connection.
    start();
  };
}
