export const API_URL =
  process.env.REACT_APP_HOST_ENV.toLowerCase() === 'debug'
    ? process.env.REACT_APP_RELAY_API_URL
    : process.env[
        `REACT_APP_RELAY_API_URL_${process.env.REACT_APP_HOST_ENV.toUpperCase()}`
      ];

export const RELAY_HUB_URL =
  process.env.REACT_APP_HOST_ENV.toLowerCase() === 'debug'
    ? process.env.REACT_APP_RELAY_HUB_URL
    : process.env[
        `REACT_APP_RELAY_HUB_URL_${process.env.REACT_APP_HOST_ENV.toUpperCase()}`
      ];

export const CLIENT_ID =
  process.env.REACT_APP_HOST_ENV.toLowerCase() === 'debug'
    ? process.env.REACT_APP_RELAY_CLIENT_ID
    : process.env[
        `REACT_APP_RELAY_CLIENT_ID_${process.env.REACT_APP_HOST_ENV.toUpperCase()}`
      ];

export const FRIENDLIFY_ERROR_MESSAGES =
  process.env.REACT_APP_HOST_ENV.toLowerCase() !== 'debug';

//Mobile browser settings
export const SKIP_MEDIA_SELECTION_FOR_MOBILE =
  process.env.REACT_APP_SKIP_MEDIA_SELECTION_FOR_MOBILE === 'true';
export const SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE =
  process.env.REACT_APP_SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE === 'true';

//Video settings
export const DISABLE_PINNING_PARTICIPANT_VIDEO =
  process.env.REACT_APP_DISABLE_PINNING_PARTICIPANT_VIDEO === 'true';
export const PRIORITIZE_REMOTE_PARTICIPANT_VIDEO =
  process.env.REACT_APP_PRIORITIZE_REMOTE_PARTICIPANT_VIDEO === 'true';

//Realtime connection settings
export const REALTIME_RECONNECT_ATTEMPTS =
  process.env.REACT_APP_SIGNALR_RECONNECT_ATTEMPTS;
export const REALTIME_INTERVAL_BETWEEN_RECONNECT_SECONDS =
  process.env.REACT_APP_SIGNALR_INTERVAL_BETWEEN_RECONNECT_SECONDS;
export const REALTIME_HOST_STATUS_POLLING_INTERVAL_SECONDS =
  process.env.REACT_APP_SIGNALR_HOST_STATUS_POLLING_INTERVAL_SECONDS;

//Raygun
export const RAYGUN_API_KEY =
  process.env.REACT_APP_HOST_ENV.toLowerCase() === 'debug'
    ? process.env.REACT_APP_RAYGUN_API_KEY
    : process.env[
        `REACT_APP_RAYGUN_API_KEY_${process.env.REACT_APP_HOST_ENV.toUpperCase()}`
      ];

export const ENABLE_DEBUG_MSGS = ['debug', 'development'].includes(
  process.env.REACT_APP_HOST_ENV.toLowerCase()
);

export const PROMPT_RECORDING_CONSENT =
  process.env.REACT_APP_PROMPT_RECORDING_CONSENT === 'true';
