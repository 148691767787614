import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {debug} from '../../Helpers/errorHelper';
import {Checkmark} from 'react-checkmark';
import {
  DEVICES_SELECT_SELECT_AUDIO_DEVICES,
  DEVICES_SELECT_SELECT_MICROPHONE,
  DEVICES_SELECT_SELECT_SPEAKER,
} from '../../Constants/textResources';
import DeviceListMenuTriggerButton from '../DeviceListMenuTriggerButton';

const AudioDevicesSelect = ({
  audioInputDevices,
  audioOutputDevices,
  currentAudioInputDevice,
  currentAudioOutputDevice,
  onInputDeviceClicked,
  onOutputDeviceClicked,
  isFullScreenMode,
  mediaControlContainerRef,
}) => {
  const [open, setOpen] = useState(false);

  const [audioInputDeviceId, setAudioInputDeviceId] = useState(currentAudioInputDevice);
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState(
    currentAudioOutputDevice
  );

  debug(currentAudioInputDevice);
  debug(currentAudioOutputDevice);

  var getCheckmark = () => <Checkmark size='small' color='#223344' />;

  var getAudioOutputDeviceListMenu = () => (
    <div>
      <div style={{marginTop: '5px'}}>
        <span className='devices-select-header'>
          <strong>{DEVICES_SELECT_SELECT_MICROPHONE}</strong>
        </span>
      </div>
      <div className='menu'>
        {audioOutputDevices.map((device) => (
          <div
            key={device.deviceId}
            className='devices-menu-item-container'
            onClick={() => {
              setAudioOutputDeviceId(device.deviceId);
              onOutputDeviceClicked(device.deviceId);
            }}>
            {device.deviceId === audioOutputDeviceId && (
              <span className='devices-menu-item-chkmrk'>{getCheckmark()}</span>
            )}
            <span
              className='menu-item'
              style={{
                marginLeft: device.deviceId === audioOutputDeviceId ? '0px' : '18px',
              }}>
              {device.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  var getAudioInputDeviceListMenu = () => (
    <div>
      <div>
        <span className='devices-select-header'>
          <strong>{DEVICES_SELECT_SELECT_SPEAKER}</strong>
        </span>
      </div>
      <div className='menu'>
        {audioInputDevices.map((device) => (
          <div
            onClick={() => {
              setAudioInputDeviceId(device.deviceId);
              onInputDeviceClicked(device.deviceId);
            }}
            className='devices-menu-item-container'
            key={device.deviceId}>
            {device.deviceId === audioInputDeviceId && (
              <span className='devices-menu-item-chkmrk'>{getCheckmark()}</span>
            )}
            <span
              className='menu-item'
              style={{
                marginLeft: device.deviceId === audioInputDeviceId ? '0px' : '18px',
              }}>
              {device.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  var getTrigger = (isOpen) => (
    <DeviceListMenuTriggerButton
      ref={isFullScreenMode ? mediaControlContainerRef : null}
      open={isOpen}
      setOpen={setOpen}
      btnId={'btn-audio-device-list'}
      imgTitle={DEVICES_SELECT_SELECT_AUDIO_DEVICES}
    />
  );

  return (
    <Popup
      trigger={(open) => getTrigger(open)}
      position='top left'
      closeOnDocumentClick
      open={open}>
      {audioInputDevices.length > 1 && getAudioInputDeviceListMenu()}
      {audioOutputDevices.length > 1 && getAudioOutputDeviceListMenu()}
    </Popup>
  );
};

AudioDevicesSelect.propTypes = {
  audioInputDevices: PropTypes.array.isRequired,
  audioOutputDevices: PropTypes.array.isRequired,
  currentAudioInputDevice: PropTypes.string.isRequired,
  currentAudioOutputDevice: PropTypes.string.isRequired,
  onInputDeviceClicked: PropTypes.func.isRequired,
  onOutputDeviceClicked: PropTypes.func.isRequired,
  isFullScreenMode: PropTypes.bool.isRequired,
  mediaControlContainerRef: PropTypes.object.isRequired,
};

export default AudioDevicesSelect;
