import rg4js from 'raygun4js'; // Import the library with this syntax
import {RAYGUN_API_KEY} from '../Helpers/configHelper';
import {debug} from '../Helpers/errorHelper';

rg4js('enableCrashReporting', true);
rg4js('apiKey', RAYGUN_API_KEY);

const saveUserInfo = (userInfo) => {
  window._raygunLogger = {user: userInfo};
};

const getSavedUserInfo = () => window._raygunLogger && window._raygunLogger.user;

export const setUserInfo = (userInfo) => {
  try {
    rg4js('setUser', userInfo);
    saveUserInfo(userInfo);
  } catch (e) {
    e.customData = e.customData || {};
    e.customData.userInfo = userInfo;
    logError(e);
  }
};

export const logError = (errorPayload) => {
  try {
    if (!errorPayload.customData) {
      errorPayload.customData = getSavedUserInfo();
    } else {
      errorPayload.customData.user = getSavedUserInfo();
    }
    rg4js('send', errorPayload);
  } catch (e) {
    debug(e);
  }
};
