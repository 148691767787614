import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {emptyImg} from '../Misc/emptyImage';

const DeviceListMenuTriggerButton = forwardRef(
  ({open, setOpen, btnId, imgTitle, ...props}, ref) => (
    <button
      id={btnId}
      className='button devicelistBtn'
      onClick={() => setOpen(!open)}
      ref={ref}
      {...props}>
      <img
        src={emptyImg}
        className={`ico-arrow-popup ${open ? 'arrow-dwn' : ''}`}
        title={imgTitle}
        alt={imgTitle}></img>
    </button>
  )
);

DeviceListMenuTriggerButton.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  btnId: PropTypes.string.isRequired,
  imgTitle: PropTypes.string.isRequired,
};

DeviceListMenuTriggerButton.displayName = 'DeviceListMenuTriggerButton';

export default DeviceListMenuTriggerButton;
