import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {getOriginalName} from '../Helpers/nameHelper';
import {debug} from '../Helpers/errorHelper';

class MainVideoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.video = createRef();
    this.isMainVideoDetached = this.isMainVideoDetached.bind(this);
  }

  componentDidMount() {
    this.props.context.Conference.attachActiveTrack(this.video.current);
  }

  componentDidUpdate() {
    /*
        UI updates after active participant changes (consequently active tracks changes)
        This requests the new active participant's video track to be attached to the newly
        rendered video element */
    debug('refreshing main video container');
    this.props.context.Conference.attachActiveTrack(this.video.current);
  }

  isMainVideoDetached = () =>
    this.props.context.Conference.eventData.activeVideoTrackDetached;

  render() {
    var activeParticipant = this.props.activeParticipant;
    if (activeParticipant) {
      var videoIsInPausedState = this.props.isVideoPaused(activeParticipant.sid);
      const {identity} = activeParticipant;
      return (
        <div className='row' id='room'>
          <div
            id='active-participant'
            className='col-xs-12 col-sm-9 col-md-10'
            style={{margin: 'auto'}}>
            <div
              className={
                'participant main ' + (this.props.isFullScreenMode ? 'fullscreen' : '')
              }
              data-identity={
                this.props.isFullScreenMode ? '' : getOriginalName(identity)
              }>
              {!videoIsInPausedState && (
                <video
                  ref={this.video}
                  autoPlay
                  playsInline
                  muted
                  style={{
                    opacity: this.isMainVideoDetached() ? '0' : '',
                  }}></video>
              )}
              {videoIsInPausedState && (
                <video
                  autoPlay
                  playsInline
                  muted
                  style={{backgroundColor: 'black'}}></video>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

MainVideoContainer.propTypes = {
  context: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  isVideoPaused: PropTypes.func.isRequired,
  isFullScreenMode: PropTypes.bool.isRequired,
  activeParticipant: PropTypes.object.isRequired,
};

export default inject('context')(observer(MainVideoContainer));
