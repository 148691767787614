import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch} from 'react-router-dom';
import {Provider} from 'mobx-react';
import RootStore from './Context/rootStore';
import {removeFromBrowserStorage} from './Helpers/storageHelper';
import ResponsiveContext from './Components/responsiveContext';
import FullScreenContext from './Components/fullscreenContext';
import App from './App';
import ErrorMessage from './Components/errorMessage';
import {ErrorBoundary} from 'react-error-boundary';
import {handleError, resetState, ErrorFallback} from './Helpers/errorHelper';
import {DEFAULT} from './Constants/deviceTypes';
import * as serviceWorker from './serviceWorker';
import {ACCESS_TOKEN, AUDIO_DEVICE_ID, VIDEO_DEVICE_ID} from './Constants/storageKeys';
import {
  WELCOME_PAGE_BROWSER_NOT_SUPPORTED,
  WELCOME_PAGE_CANT_ACCESS_MEDIA,
} from './Constants/textResources';

const {
  CoreConferenceEngine: {isBrowserSupported, clearAllDefaultMediaDeviceIds},
} = require('twilio-conferencing-core-engine');

//Remove last set api access token info and selected devices
removeFromBrowserStorage(ACCESS_TOKEN);
removeFromBrowserStorage(AUDIO_DEVICE_ID);
removeFromBrowserStorage(VIDEO_DEVICE_ID);

clearAllDefaultMediaDeviceIds();

if (!isBrowserSupported()) {
  ReactDOM.render(
    <ErrorMessage show={() => true} message={WELCOME_PAGE_BROWSER_NOT_SUPPORTED} />,
    document.getElementById('root')
  );
} else {
  (async () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        return devices.every(function (device) {
          return !(device.deviceId && device.label);
        });
      })
      .then(async () => {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
      });
  })()
    .then(() => {
      ReactDOM.render(
        <React.StrictMode>
          <Provider context={RootStore}>
            <BrowserRouter>
              <Switch>
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={(error, componentStack) =>
                    handleError(error, componentStack, RootStore)
                  }
                  onReset={() => {
                    resetState(RootStore);
                  }}>
                  <ResponsiveContext>
                    <FullScreenContext>
                      <App deviceType={DEFAULT} />
                    </FullScreenContext>
                  </ResponsiveContext>
                </ErrorBoundary>
              </Switch>
            </BrowserRouter>
          </Provider>
        </React.StrictMode>,
        document.getElementById('root')
      );
    })
    .catch(() => {
      ReactDOM.render(
        <ErrorMessage show={() => true} message={WELCOME_PAGE_CANT_ACCESS_MEDIA} />,
        document.getElementById('root')
      );
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
