import React from 'react';
import {observer, inject} from 'mobx-react';
import {MOBILE_OR_SIMILAR} from '../Constants/deviceTypes';
import {SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE} from '../Helpers/configHelper';
import PropTypes from 'prop-types';

const responsiveOverrides = {
  MOBILE_OR_SIMILAR: {
    nav: {
      marginBottom: '0px',
    },
    logoImg: {
      width: '150px',
      height: '37px',
    },
    logoText: {
      fontWeight: '300',
    },
  },
};

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.getResponsiveOverrides = this.getResponsiveOverrides.bind(this);
  }

  getResponsiveOverrides = () => {
    if (
      this.props.deviceType === MOBILE_OR_SIMILAR &&
      SHOW_COMPACT_VIDEO_VIEW_FOR_MOBILE
    ) {
      return responsiveOverrides[this.props.deviceType];
    }

    return {};
  };

  render() {
    return (
      <nav
        className='navbar navbar-expand-sm navbar-light bg-light'
        style={{
          marginBottom: '100px',
          ...this.props.styleOverride,
          ...this.getResponsiveOverrides().nav,
        }}>
        <button className='navbar-brand logoBtn'>
          <img
            src={process.env.PUBLIC_URL + this.props.context.UI.relayData.logoUrl}
            width='225'
            height='56'
            style={{...this.getResponsiveOverrides().logoImg}}
            className='d-inline-block align-top'
            alt=''
          />
          <span
            style={{
              margin: '0 12px',
              verticalAlign: '-webkit-baseline-middle',
              fontSize: 'large',
              fontWeight: '500',
              color: 'BROWN',
              ...this.getResponsiveOverrides().logoText,
            }}>
            Relay
          </span>
        </button>
      </nav>
    );
  }
}

Nav.propTypes = {
  context: PropTypes.object.isRequired,
  styleOverride: PropTypes.object,
  deviceType: PropTypes.string.isRequired,
};

export default inject('context')(observer(Nav));
