import {toQueryString} from './urlHelper';
import {setToken, getToken, removeToken} from './tokenHelper';
import {API_URL, CLIENT_ID} from './configHelper';
import {debug} from '../Helpers/errorHelper';

const apiUrl = API_URL;
const clientId = CLIENT_ID;

class ApiHelper {
  constructor() {
    this.tokenRefreshTimer = null;
  }

  init = async () => {
    return new Promise((resolve, error) => {
      this.validateAndRefreshTokenState().then(resolve).catch(error);
    });
  };

  validateAndRefreshTokenState = async () => {
    return new Promise((resolve, error) => {
      var token = getToken();
      if (!token) {
        debug('Setting token');
        this.setupTokenRetrieval().then(resolve).catch(error);
      } else {
        resolve(); //there's a token -  proceed
      }
    });
  };

  getApiAccessToken = async (clientId) => {
    return new Promise((resolve, error) => {
      this.get(`token/GetApiAccessToken/${clientId}`, null, true)
        .then(resolve)
        .catch(error);
    });
  };

  setupTokenRetrieval = async () => {
    return new Promise((resolve, error) => {
      this.getApiAccessToken(clientId)
        .then((res) => {
          if (res.isValid) {
            setToken(res.token);
            this.tokenRefreshTimer = setInterval(
              res.expiringInSeconds * 1000,
              this.refreshAccessToken
            );
            debug('Token successfully set');
            resolve();
          }
        })
        .catch((err) => {
          debug('Token failed to set');
          error(err);
        });
    });
  };

  refreshAccessToken = async () => {
    this.getApiAccessToken(clientId)
      .then((res) => {
        setToken(res.token);
      })
      .catch((e) => {
        debug('Token expiring but cant refresh' + e.message);
      });
  };

  resetToken = () => {
    removeToken();
  };

  get = async (endpoint, queryParams, isTokenRequest = false) => {
    return new Promise((resolve, error) => {
      let fetchFunc = () => {
        var headers = new Headers();
        var token = getToken();

        if (token) headers.append('Authorization', `Bearer ${token}`);
        else if (!isTokenRequest) {
          //Don't have a token - HALT
          debug('Attempt to make GET request without token');
          error('Attempt to make GET request without token');
        }

        var options = {
          method: 'GET',
          headers,
        };

        var url = apiUrl.concat(endpoint);

        if (queryParams) {
          queryParams =
            typeof queryParams === 'object'
              ? toQueryString(queryParams)
              : typeof queryParams === 'string'
              ? queryParams
              : '';

          url = url.concat(`?${queryParams}`);
        }

        const request = new Request(url, options);
        fetch(request)
          .then((res) => {
            if (res.status === 200 || res.status === 202) {
              return res.clone().json();
            }

            return null;
          })
          .then((response) => {
            if (response !== null) {
              resolve(response);
            } else {
              error(`Could not fetch ${url}`);
            }
          })
          .catch((e) => {
            debug(e);
            error(e);
          });
      };

      if (!isTokenRequest)
        this.validateAndRefreshTokenState()
          .then(() => {
            fetchFunc();
          })
          .catch((e) => {
            error(e);
          });
      else {
        fetchFunc();
      }
    });
  };

  post = async (endpoint, model, isTokenRequest = false) => {
    return new Promise((resolve, error) => {
      let fetchFunc = () => {
        var headers = new Headers();
        var token = getToken();
        if (token) headers.append('Authorization', `Bearer ${token}`);
        else if (!isTokenRequest) {
          //Don't have a token - HALT
          debug('Attempt to make GET request without token');
          error('Attempt to make GET request without token');
        }

        headers.append('Content-Type', 'application/json');

        var options = {
          method: 'POST',
          headers,
          body: JSON.stringify(model),
        };

        var url = apiUrl.concat(endpoint);
        const request = new Request(url, options);
        fetch(request)
          .then((res) => res.json())
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            debug(e);
            error(e);
          });
      };
      if (!isTokenRequest) {
        this.validateAndRefreshTokenState().then(() => {
          fetchFunc();
        });
      } else {
        fetchFunc();
      }
    });
  };
}

export default new ApiHelper();
