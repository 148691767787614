import {
  saveInBrowserStorage,
  getFromBrowserStorage,
  removeFromBrowserStorage,
} from './storageHelper';

import {ACCESS_TOKEN} from '../Constants/storageKeys';

export const setToken = (token) => {
  saveInBrowserStorage(ACCESS_TOKEN, token);
};

export const getToken = () => {
  return getFromBrowserStorage(ACCESS_TOKEN);
};

export const hasToken = () => {
  return getToken().length;
};

export const getFormattedBearerToken = () => {
  return `${getToken()}`;
};

export const removeToken = () => {
  removeFromBrowserStorage(ACCESS_TOKEN);
};
