import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {observer, inject} from 'mobx-react';
import {
  SELECT_CAMERA_CAMERA,
  SELECT_CAMERA_PREVIEW,
  MEDIA_SELECTOR_JOIN_MEETING_WITH_CAMERA_TURNED_OFF,
  MEDIA_SELECTOR_CHOOSE_CAMERA,
} from '../Constants/textResources';

class SelectCamera extends React.Component {
  constructor(props) {
    super(props);
    this.handleVideoDeviceChange = this.handleVideoDeviceChange.bind(this);
    this.handleTurnOffCamToggle = this.handleTurnOffCamToggle.bind(this);
    this.videoPreview = createRef();
  }

  componentDidMount() {
    this.props.setCurrentVideoDeviceId(this.props.localVideoInputDevices[0].deviceId);
    this.props.context.Conference.beginVideoPreview(this.videoPreview.current);
  }

  handleVideoDeviceChange = (event) => {
    this.props.setCurrentVideoDeviceId(event.target.value);
    this.props.context.Conference.beginVideoPreview(this.videoPreview.current);
  };

  handleTurnOffCamToggle = () => {
    this.props.setJoinMeetingWithCameraTurnedOff(
      !this.props.joinMeetingWithCameraTurnedOff
    );
  };

  render() {
    return (
      <div>
        <div className='modal-content'>
          <div className='modal-body' style={{textAlign: 'center'}}>
            {this.props.localVideoInputDevices &&
              this.props.localVideoInputDevices.length && (
                <div>
                  <label htmlFor='cam-select'>
                    <h6>{MEDIA_SELECTOR_CHOOSE_CAMERA}</h6>
                  </label>
                  {this.props.localVideoInputDevices.length > 1 && (
                    <select
                      id='cam-select'
                      style={{
                        width: '100%',
                        height: '32px',
                        marginBottom: '10px',
                      }}
                      defaultValue={this.props.localVideoInputDevices[0]}
                      onChange={this.handleVideoDeviceChange}>
                      {this.props.localVideoInputDevices.map(({deviceId, label}, i) => (
                        <option value={deviceId} key={i}>
                          {label}
                        </option>
                      ))}
                    </select>
                  )}
                  {this.props.localVideoInputDevices.length === 1 && (
                    <div>
                      <h7>
                        {SELECT_CAMERA_CAMERA +
                          ' - ' +
                          this.props.localVideoInputDevices[0].label}
                      </h7>
                      <br />
                      <h7>{SELECT_CAMERA_PREVIEW}:</h7>
                    </div>
                  )}
                  <video
                    autoPlay
                    ref={this.videoPreview}
                    muted
                    playsInline
                    style={{margin: '10px 0', width: '60%'}}></video>
                  <div id='tgl-mute-video-container'>
                    <Toggle
                      id='tgl-turn-cam-off'
                      defaultChecked={this.props.joinMeetingWithCameraTurnedOff}
                      onChange={this.handleTurnOffCamToggle}
                    />
                    <label id='label-tgl-turn-cam-off' htmlFor='tgl-turn-cam-off'>
                      {MEDIA_SELECTOR_JOIN_MEETING_WITH_CAMERA_TURNED_OFF}
                    </label>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

SelectCamera.propTypes = {
  context: PropTypes.object.isRequired,
  setCurrentVideoDeviceId: PropTypes.func.isRequired,
  localVideoInputDevices: PropTypes.array.isRequired,
  setJoinMeetingWithCameraTurnedOff: PropTypes.func.isRequired,
  joinMeetingWithCameraTurnedOff: PropTypes.bool.isRequired,
};

export default inject('context')(observer(SelectCamera));
