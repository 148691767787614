import {observable, runInAction, makeAutoObservable} from 'mobx';
import {ON, OFF} from '../Constants/mediaControlState';
import {CoreConferenceEngine} from 'twilio-conferencing-core-engine';
import {AUDIO, VIDEO} from '../Constants/mediaType';
import {
  NONE,
  CONNECTING,
  CONNECTED,
  DISCONNECTED,
  FAILED,
  TERMINATED,
} from '../Constants/meetingStatus';
import {WARNING, ERROR} from '../Constants/workflowStatus';
import {debug} from '../Helpers/errorHelper';
import {ACTIVE, INACTIVE} from '../Constants/mediaTrackState';
import {PRIORITIZE_REMOTE_PARTICIPANT_VIDEO} from '../Helpers/configHelper';

const {
  registerCallbacks,
  init,
  joinRoom,
  leaveRoom,
  turnOnMyVideo,
  turnOnMyAudio,
  turnOffMyVideo,
  turnOffMyAudio,
  changeVideoSource,
  changeAudioSource,
  canScreenshare,
  startScreenShare,
  stopScreenShare,
  attachMicVolumeListener,
  isMobile,
  assignDefaultAudioInputDeviceId,
  assignDefaultAudioOutputDeviceId,
  assignDefaultVideoInputDeviceId,
  clearAllDefaultMediaDeviceIds,
} = CoreConferenceEngine;

const connectOptions = require('../connectOptions.json');

export default class ConferenceStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.relayService = rootStore.UI.relayService;
    makeAutoObservable(this);
  }

  initEventData = () => {
    return {
      participants: observable([]),
      participantsAudioTracks: observable([]),
      participantsThumbnailTrackRefs: observable([]),
      participantsThumbnailTracksPendingMount: observable([]),
      participantsAudioElementRefs: observable([]),
      pausedParticipantVideos: observable([]),
      activeParticipant: observable.box(null),
      videoDeviceId: observable.box(null),
      isActiveParticipantPinned: observable.box(false),
      audioInputDeviceId: observable.box(null),
      audioOutputDeviceId: observable.box(null),
      activeVideoTrack: null,
      activeVideoElement: null,
      activeVideoTrackDetached: false,
      activeVideoThumbnailTrackDetached: {},
      dominantSpeaker: null,
      localParticipant: observable.box(null),
      localAudioTrack: null,
      localVideoTrack: null,
      currentRoom: null,
      currentRoomId: null,
      beforeUnloadHandler: null,
      visibilityChangeHandler: null,
      mediaControlState: observable({audio: ON, video: ON, screenshare: OFF}),
      isVideoSupported: true,
      isScreenCasting: false,
      isScreencastSupported: canScreenshare(),
      isMobile: isMobile,
    };
  };

  eventData = this.initEventData();
  meetingStatus = NONE;
  audioTrackChangeListener = null;

  turnLocalAudioOn = async (deviceId) => {
    return new Promise((resolve, reject) => {
      turnOnMyAudio(deviceId)
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  };

  turnLocalAudioOff = async () => {
    return new Promise((resolve, reject) => {
      turnOffMyAudio()
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));

      //https://github.com/twilio/twilio-video.js/issues/656#issuecomment-499207086
      runInAction(() => {
        this.removeActiveLocalAudioTrack();
      });
    });
  };

  turnLocalVideoOn = async (deviceId) => {
    return new Promise((resolve, reject) => {
      turnOnMyVideo(deviceId)
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  };

  turnLocalVideoOff = async () => {
    return new Promise((resolve, reject) => {
      turnOffMyVideo()
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));

      runInAction(() => {
        this.removeActiveLocalVideoTrack();
      });
    });
  };

  setRemoteParticipantVideoStateToPaused = async (remoteParticipantId) => {
    if (!remoteParticipantId || !remoteParticipantId.length) return;

    runInAction(() => {
      debug('Participant video paused - ' + remoteParticipantId);
      debug(
        'Current active participant sid - ' + this.eventData.activeParticipant.get().sid
      );
      this.eventData.pausedParticipantVideos.push(remoteParticipantId);
    });
  };

  setRemoteParticipantVideoStateToResumed = async (remoteParticipantId) => {
    if (!remoteParticipantId || !remoteParticipantId.length) return;

    runInAction(() => {
      let index = this.eventData.pausedParticipantVideos.indexOf(remoteParticipantId);
      if (index) {
        this.eventData.pausedParticipantVideos.splice(index, 1);
      }
    });
  };

  terminateCurrentMeeting = async (initiatedByRemoteHost = false) => {
    this.setMeetingStatus(TERMINATED);
    this.rootStore.UI.endCurrentMeetingAndRedirectToWelcomePage(initiatedByRemoteHost);
  };

  isParticipantVideoInPausedState = (participantSid) => {
    return runInAction(() => {
      return this.eventData.pausedParticipantVideos.includes(participantSid);
    });
  };

  isScreenCasting = () => this.eventData.isScreenCasting;

  setScreenCastIsOngoing = (isOngoing) => {
    runInAction(() => {
      this.eventData.isScreenCasting = isOngoing;
    });
  };

  castScreen = async () => {
    return new Promise((resolve, reject) => {
      if (this.canScreenshare()) {
        startScreenShare()
          .then(() => {
            this.setScreenCastIsOngoing(true);
            resolve();
          })
          .catch((e) => {
            this.setScreenCastIsOngoing(false);
            reject(e);
          });
      }
    });
  };

  stopScreencast = async () => {
    return new Promise((resolve, reject) => {
      if (this.canScreenshare() && this.isScreenCasting()) {
        stopScreenShare()
          .then(() => {
            this.setScreenCastIsOngoing(false);
            resolve();
          })
          .catch((e) => reject(e));
      }
    });
  };

  mediaControlStateFunc = {
    audioOn: this.turnLocalAudioOn,
    audioOff: this.turnLocalAudioOff,
    videoOn: this.turnLocalVideoOn,
    videoOff: this.turnLocalVideoOff,
    screenshareOn: this.castScreen,
    screenshareOff: this.stopScreencast,
  };

  getMediaControlState = (kind) => {
    return this.eventData.mediaControlState[kind];
  };

  resetEventData = () => {
    this.eventData = this.initEventData();
    this.meetingStatus = NONE;
  };

  setMeetingStatus = (status) => {
    runInAction(() => {
      this.meetingStatus = status;
    });
  };

  meetingWasTerminatedByHost = () => {
    return runInAction(() => {
      return this.meetingStatus === TERMINATED;
    });
  };

  meetingIsLive = () => {
    return runInAction(() => {
      return (
        this.meetingStatus !== NONE &&
        this.meetingStatus !== DISCONNECTED &&
        this.meetingStatus !== FAILED &&
        this.meetingStatus !== TERMINATED
      );
    });
  };

  getAudioTrackState = () => {
    return this.eventData.localAudioTrack !== null ? ON : OFF;
  };

  getVideoTrackState = () => {
    return this.eventData.localVideoTrack !== null ? ON : OFF;
  };

  getScreenCastState = () => {
    return this.eventData.isScreenCasting ? ON : OFF;
  };

  updateAllMediaControlStates = () => {
    runInAction(() => {
      this.eventData.mediaControlState = {
        audio: this.getAudioTrackState(),
        video: this.getVideoTrackState(),
        screenshare: this.getScreenCastState(),
      };
    });
  };

  updateMediaControlState = (kind, value) => {
    runInAction(() => {
      this.eventData.mediaControlState[kind] = value;
    });
  };

  saveParticipantsAudioElementRef = (participantSid, element) => {
    runInAction(() => {
      this.eventData.participantsAudioElementRefs.push({
        sid: participantSid,
        ref: element,
      });
    });
  };

  changeAllParticipantAudioOutput = () => {
    runInAction(() => {
      var targetDeviceId = this.eventData.audioOutputDeviceId.value;
      //change sink id for participant thumbnail audio elements refs
      if (targetDeviceId && this.eventData.participantsThumbnailTrackRefs.length) {
        this.eventData.participantsThumbnailTrackRefs.forEach(
          (participantsThumbnailTrackRef) => {
            let audioElement = participantsThumbnailTrackRef.audio;
            this.updateAudioSinkId(audioElement);
          }
        );
      }

      //change sink id for participant audio elements
      if (targetDeviceId && this.eventData.participantsAudioElementRefs.length) {
        this.eventData.participantsAudioElementRefs.forEach(
          (participantsAudioElementRef) => {
            let audioElement = participantsAudioElementRef.ref;
            this.updateAudioSinkId(audioElement);
          }
        );
      }
    });
  };

  removeParticipantAudioElementRef = (participantSid) => {
    runInAction(() => {
      let refs = this.eventData.participantsAudioElementRefs;
      var existing = refs.find((ref) => ref.sid === participantSid);
      if (existing) {
        //remove
        var index = refs.indexOf(existing);
        this.eventData.participantsAudioElementRefs.splice(index, 1);
      }
    });
  };

  updateAudioSinkId = (element) => {
    if (element && element.setSinkId) {
      element.setSinkId(this.eventData.audioOutputDeviceId.value);
    }
  };

  canScreenshare = () => this.eventData.isScreencastSupported;

  setupMeetingPrerequisites = () => {
    var callbacks = registerCallbacks(
      this.onRoomConnected,
      this.onRoomDisconnected,
      this.onParticipantConnected,
      this.onParticipantDisconnected,
      this.onParticipantSubscribedTrack,
      this.onParticipantUnsubscribedTrack,
      this.onDominantSpeakerChanged,
      this.onExistingParticipantsReportingComplete
    );

    //init
    var isVideoUnsupported;
    try {
      isVideoUnsupported = init(callbacks);
    } catch (e) {
      runInAction(() => {
        this.rootStore.UI.status = ERROR;
        this.rootStore.UI.lastErrorMessage = e.message;
      });
      return false; //initialize unsuccessful
    }

    if (isVideoUnsupported) {
      runInAction(() => {
        this.eventData.isVideoSupported = false;
        this.rootStore.UI.status = WARNING;
        this.rootStore.UI.lastErrorMessage =
          'Warning - Video is not available on this device';
      });
    }

    if (this.eventData.audioInputDeviceId.get() == null) {
      runInAction(() => {
        this.rootStore.UI.status = ERROR;
        this.rootStore.UI.lastErrorMessage = 'Sorry - Audio seems to be unavailable';
      });
      return false; //initialize unsuccessful
    }

    return true;
  };

  startMeeting = () => {
    return new Promise((resolve, error) => {
      this.setMeetingStatus(CONNECTING);

      var token = this.rootStore.UI.relayData.userAccessToken;

      var options = isMobile ? connectOptions.mobile : connectOptions.desktop;
      options.audio.deviceId = this.eventData.audioInputDeviceId.get();
      options.video.deviceId = this.eventData.videoDeviceId.get();

      joinRoom(token, this.rootStore.UI.relayData.currentRoomUniqueName, options)
        .then((room) => {
          this.setMeetingStatus(CONNECTED);

          if (this.rootStore.UI.relayData.joinMeetingWithCameraTurnedOff) {
            room.localParticipant.videoTracks.forEach((videoTrack) => {
              videoTrack.track.disable();
            });
            this.updateMediaControlState(VIDEO, OFF);
          } else {
            this.turnLocalVideoOn();
          }

          if (this.rootStore.UI.relayData.joinMeetingWithMicMuted) {
            room.localParticipant.audioTracks.forEach((audioTrack) => {
              audioTrack.track.disable();
            });
            this.updateMediaControlState(AUDIO, OFF);
          } else {
            this.turnLocalAudioOn();
          }

          resolve();
        })
        .catch((e) => {
          this.setMeetingStatus(FAILED);
          this.rootStore.UI.reportError(e);
          error(e);
        });
    });
  };

  endMeeting = () => {
    return new Promise((resolve, reject) => {
      this.setMeetingStatus(DISCONNECTED);

      runInAction(() => {
        if (this.rootStore.UI.relayData.isAgent) {
          this.reportMeetingCompleted(
            this.rootStore.UI.relayData.conferenceShortId,
            this.eventData.currentRoom?.participants.length ||
              this.eventData.participants.length,
            this.eventData.currentRoom?.duration || 0
          )
            .then(() => resolve())
            .catch((e) => reject(e));
        } else {
          resolve();
        }
        clearAllDefaultMediaDeviceIds();
        this.resetEventData();
        leaveRoom();
      });
    });
  };

  detachCurrentlyActiveTrack = () => {
    runInAction(() => {
      var activeParticipant = this.eventData.activeParticipant.get();
      debug('Current active participant: ', activeParticipant);
      if (activeParticipant) {
        const {track: activeVideoTrack} =
          Array.from(activeParticipant.videoTracks.values())[0] || {};
        if (activeVideoTrack) {
          debug('detaching active video track:', activeVideoTrack);
          debug(
            'detaching from active video element:',
            this.eventData.activeVideoElement
          );

          activeVideoTrack.detach(this.eventData.activeVideoElement);
          this.eventData.activeVideoElement.load();

          this.eventData.activeVideoTrack = null;
          this.eventData.activeVideoTrackDetached = true;
        }
      }
    });
  };

  attachActiveTrack = (elem) => {
    if (!elem) {
      return;
    }

    runInAction(() => {
      this.eventData.activeVideoElement = elem;
      if (this.eventData.activeVideoTrack) {
        this.eventData.activeVideoTrack.attach(elem);
        this.eventData.activeVideoTrackDetached = false;
      } else {
        debug('no active video found to attach to');
      }
    });
  };

  saveOrUpdateParticipantThumbnailTrackRefs = (participantSid, audioRef, videoRef) => {
    runInAction(() => {
      let refs = this.eventData.participantsThumbnailTrackRefs;
      var existing = refs.find((ref) => ref.sid === participantSid);
      if (existing) {
        //update
        existing.audio = audioRef;
        existing.video = videoRef;
      } else {
        //insert
        this.eventData.participantsThumbnailTrackRefs.push({
          sid: participantSid,
          audio: audioRef,
          video: videoRef,
        });
      }
    });
  };

  removeParticipantThumbnailTrackRefs = (participantSid) => {
    runInAction(() => {
      let refs = this.eventData.participantsThumbnailTrackRefs;
      var existing = refs.find((ref) => ref.sid === participantSid);
      if (existing) {
        //remove
        var index = refs.indexOf(existing);
        this.eventData.participantsThumbnailTrackRefs.splice(index, 1);
      }
    });
  };

  getParticipantThumbnailTrackRefs = (participantSid) => {
    //TODO check - run in action?
    let refs = this.eventData.participantsThumbnailTrackRefs;
    var existing = refs.find((ref) => ref.sid === participantSid);
    if (existing) {
      //found
      return {audio: existing.audio, video: existing.video};
    }

    return null;
  };

  isParticipantThumbnailVideoTrackDetached = (participantSid) => {
    return !!this.eventData.activeVideoThumbnailTrackDetached[participantSid];
  };

  togglePinningActiveParticipantVideo = (participant) => {
    runInAction(() => {
      if (
        this.isActiveParticipant(participant) &&
        this.eventData.isActiveParticipantPinned
      ) {
        this.setVideoPriority(participant, null);
        this.eventData.isActiveParticipantPinned = false;
        this.setCurrentActiveParticipant(participant);
      } else {
        if (this.eventData.isActiveParticipantPinned) {
          this.setVideoPriority(this.eventData.activeParticipant.value, null);
        }

        this.setVideoPriority(participant, 'high');
        this.eventData.isActiveParticipantPinned = true;
        this.setActiveParticipant(participant);
      }
    });
  };

  setVideoPriority = (participant, priority) => {
    participant.videoTracks.forEach((publication) => {
      const track = publication.track;
      if (track && track.setPriority) {
        track.setPriority(priority);
      }
    });
  };

  setActiveVideoTrack = (track) => {
    if (track) {
      runInAction(() => {
        this.eventData.activeVideoTrack = track;
      });
    }
  };

  getVideoTrackWithHighestPriority = (videoTracks) => {
    var tracks = Array.from(videoTracks.values());

    if (!tracks.length) return {};

    if (tracks.length === 1) {
      return tracks[0];
    } else if (tracks.length > 1) {
      var tracksWithHighestPriority = tracks.filter((x) => x.publishPriority === 'high');
      var tracksWithStandardPriority = tracks.filter(
        (x) => x.publishPriority === 'standard'
      );
      var tracksWithLowPriority = tracks.filter((x) => x.publishPriority === 'low');

      if (tracksWithHighestPriority.length) {
        return tracksWithHighestPriority[0];
      } else if (tracksWithStandardPriority.length) {
        return tracksWithStandardPriority[0];
      } else if (tracksWithLowPriority.length) {
        return tracksWithLowPriority[0];
      } else {
        return tracks[tracks.length - 1]; //priority not set just return the last video track published
      }
    } else {
      return {};
    }
  };

  setActiveParticipant = (
    participant,
    attachVideoTrack = true,
    forceRefreshThumbnailAndVideo = false
  ) => {
    if (!participant) {
      return;
    }

    var activeParticipant = this.eventData.activeParticipant.get();
    runInAction(() => {
      if (
        !activeParticipant ||
        activeParticipant.sid !== participant.sid ||
        forceRefreshThumbnailAndVideo
      ) {
        this.detachCurrentlyActiveTrack();
        this.eventData.activeParticipant.set(participant);

        if (attachVideoTrack) {
          const {track: activeTrack} = this.getVideoTrackWithHighestPriority(
            participant.videoTracks
          );
          if (activeTrack) this.setActiveVideoTrack(activeTrack);
          debug('Active video track: ', activeTrack);
          this.attachTrack(this.eventData.activeVideoTrack, participant, true);
        }
      }
    });
  };

  /**
   * Set the current active Participant in the Room.
   * @param dominantSpeaker - the dominant speaker participant in the room
   * @param localParticipant - the local participant
   */
  setCurrentActiveParticipant = (dominantSpeaker, localParticipant) => {
    runInAction(() => {
      if (!dominantSpeaker && !localParticipant) {
        if (this.eventData.currentRoom) {
          dominantSpeaker = this.eventData.currentRoom.dominantSpeaker;
          localParticipant = this.eventData.currentRoom.localParticipant;
        } else {
          //Log
          //No valid session
          return;
        }
      }
      this.setActiveParticipant(dominantSpeaker || localParticipant, true);
    });
  };

  isLocalParticipant = (participant) => {
    return (
      this.eventData.currentRoom &&
      participant &&
      participant.sid === this.eventData.currentRoom.localParticipant.sid
    );
  };

  isActiveParticipant = (participant) => {
    return participant.sid === this.eventData.activeParticipant.get().sid;
  };

  saveTrackPendingMount = (track, participantSid) => {
    if (
      this.eventData.participantsThumbnailTracksPendingMount[participantSid] &&
      !this.eventData.participantsThumbnailTracksPendingMount[participantSid].includes(
        track
      )
    ) {
      this.eventData.participantsThumbnailTracksPendingMount[participantSid].push(track);
    } else {
      this.eventData.participantsThumbnailTracksPendingMount[participantSid] = [track];
    }
  };

  removeTrackPendingMount = (participantSid, track) => {
    runInAction(() => {
      if (!this.eventData.participantsThumbnailTracksPendingMount[participantSid]) {
        return;
      }

      if (!track) {
        //delete all
        delete this.eventData.participantsThumbnailTracksPendingMount[participantSid];
      } else {
        //delete specific track kind
        var toRemoveTrack = this.eventData.participantsThumbnailTracksPendingMount[
          participantSid
        ].find((t) => t.kind === track.kind);
        if (toRemoveTrack) {
          //remove
          var index =
            this.eventData.participantsThumbnailTracksPendingMount[
              participantSid
            ].indexOf(toRemoveTrack);
          if (index > -1)
            this.eventData.participantsThumbnailTracksPendingMount[participantSid].splice(
              index,
              1
            );
        }
      }
    });
  };

  attachAnyPendingThumnailTracks = (callback) => {
    runInAction(() => {
      var sIds = Object.keys(this.eventData.participantsThumbnailTracksPendingMount);
      if (sIds && sIds.length) {
        sIds.forEach((sId) => {
          var participant = this.eventData.participants.find((p) => p.sid === sId);
          if (participant) {
            var tracks = this.eventData.participantsThumbnailTracksPendingMount[sId];
            tracks.forEach((track) => this.attachTrack(track, participant));
          }
        });
      }
      if (callback) {
        callback(
          //there are no tracks pending mount for every participant
          this.eventData.participantsThumbnailTracksPendingMount.every(
            (entry) => entry.length === 0
          )
        );
      }
    });
  };

  //whether show participant video in main container
  shouldRenderVideoInMainContainer = (isParticipantLocal) => {
    if (!this.mainVideoIsActive()) {
      //container has no video, render
      return true;
    } else if (!!PRIORITIZE_REMOTE_PARTICIPANT_VIDEO && !isParticipantLocal) {
      //remote video prioritized and participant is remote
      return true;
    } else if (PRIORITIZE_REMOTE_PARTICIPANT_VIDEO) {
      //remote video is prioritized, but participant is local
      return false;
    } else {
      //remote video is not prioritized
      return true;
    }
  };

  mainVideoIsActive = () =>
    this.eventData.activeVideoElement && this.eventData.activeVideoElement.srcObject;

  /*
   * Attach a Track to the DOM.
   * @param track - the Track to attach
   * @param participant - the Participant which published the Track
   */
  attachTrack = (track, participant, switchVideo = false) => {
    runInAction(() => {
      // Attach the Participant's Track to the thumbnail.
      var isParticipantLocal = this.isLocalParticipant(participant);
      var trackRefs = this.getParticipantThumbnailTrackRefs(participant.sid);
      if (trackRefs) {
        if (track && track.attach) {
          var element = track.attach(trackRefs[`${track.kind}`]);
          if (track.kind === AUDIO) {
            this.saveParticipantsAudioElementRef(participant.sid, element);
            this.updateAudioSinkId(element);
          }

          this.eventData.activeVideoThumbnailTrackDetached[participant.sid] = !(
            track.kind === VIDEO
          );
          this.removeTrackPendingMount(participant.sid, track);
          debug(
            `${track.kind} track attached for participant ${
              participant.sid
            } isLocal:${this.isLocalParticipant(participant)}`
          );
        }
      } else {
        //new participant - does not have thumbnail track reference
        this.saveTrackPendingMount(track, participant.sid);
        debug(
          `${track.kind} track ref not yet registered for participant ${participant.sid} isLocal:${isParticipantLocal}`
        );
      }
      // If the attached Track is a VideoTrack that is published by the active
      // Participant, then attach it to the main video as well.

      if (
        track &&
        track.kind === VIDEO &&
        !!this.eventData.activeVideoElement &&
        (this.shouldRenderVideoInMainContainer(isParticipantLocal) || switchVideo)
      ) {
        debug('attaching new track for participant', participant);
        debug('current activeParticipant', this.eventData.activeParticipant.get());
        track.attach(this.eventData.activeVideoElement);
        this.eventData.activeVideoElement.load();
      }
    });
  };

  /**
   * Detach a Track from the DOM.
   * @param track - the Track to be detached
   * @param participant - the Participant that is publishing the Track
   */
  detachTrack = (track, participant) => {
    if (!participant) return;
    runInAction(() => {
      // Detach the Participant's Track from the thumbnail.
      var trackRefs = this.getParticipantThumbnailTrackRefs(participant.sid);
      if (trackRefs) {
        if (track && track.detach) {
          track.detach(trackRefs[`${track.kind}`]);
          trackRefs[`${track.kind}`].load();
          this.eventData.activeVideoThumbnailTrackDetached[participant.sid] =
            track.kind === VIDEO;

          if (track.kind === AUDIO) {
            this.removeParticipantThumbnailTrackRefs(participant.sid);
          }
        }
      }

      var activeParticipant = this.eventData.activeParticipant.get();

      // If the attached Track is a VideoTrack that is published by the active
      // Participant, then detach it from the main video as well.
      if (
        track &&
        track.kind === VIDEO &&
        !!participant &&
        !!activeParticipant &&
        participant.sid === activeParticipant.sid &&
        !!this.eventData.activeVideoElement
      ) {
        if (track.detach) {
          track.detach(this.eventData.activeVideoElement);
          this.eventData.activeVideoElement.load();
        }
      }
    });
  };

  runBeforeUnload = () => {
    runInAction(() => {
      if (this.eventData.beforeUnloadHandler) {
        this.eventData.beforeUnloadHandler().then(() => {
          this.relayService.resetToken();
        });
      }
    });
  };

  runWhenVisibilityChanges = (isVisible) => {
    if (this.eventData.isMobile) {
      isVisible ? turnOnMyVideo() : turnOffMyVideo();
    }
  };

  indicateVisibilityChanged = (isVisible) => {
    if (this.eventData.visibilityChangeHandler)
      this.eventData.visibilityChangeHandler(isVisible);
  };

  toggleMediaState = (kind) => {
    runInAction(async () => {
      var currentState = this.eventData.mediaControlState[kind];
      var requestedState = currentState === ON ? OFF : ON;

      //Turn on or off the media based on state
      var key = kind.concat(requestedState);
      this.mediaControlStateFunc[key]()
        .then(() => {
          this.eventData.mediaControlState[kind] = requestedState;
        })
        .catch((e) => {
          debug(
            `An exception occured when transitioning media control state - Key - ${key}, error - ${
              e.message || e
            }`
          );
        });
    });
  };

  removeActiveLocalAudioTrack = () => {
    runInAction(() => {
      if (this.eventData.localAudioTrack) {
        if (this.eventData.localAudioTrack.stop) {
          this.eventData.localAudioTrack.stop();
        }
        this.eventData.localAudioTrack = null;
      }
    });
  };

  removeActiveLocalVideoTrack = () => {
    runInAction(() => {
      debug('removing last localVideoTrack');
      if (this.eventData.localVideoTrack) {
        if (this.eventData.localVideoTrack.stop) {
          debug('stopping last local video track');
          this.eventData.localVideoTrack.stop();
        }
        this.eventData.localVideoTrack = null;
      }
    });
  };

  assignNewLocalAudioTrack = (track) => {
    if (track && track.kind === AUDIO && this.eventData.localAudioTrack !== track) {
      runInAction(() => {
        this.removeActiveLocalAudioTrack();
        this.eventData.localAudioTrack = track;
      });
    }
  };

  assignNewLocalVideoTrack = (track) => {
    if (track && track.kind === VIDEO && this.eventData.localVideoTrack !== track) {
      debug('Assigning new local video track');
      runInAction(() => {
        this.removeActiveLocalVideoTrack();
        this.eventData.localVideoTrack = track;
      });
    }
  };

  beginAudioPreview = (maxLevel, render) => {
    this.renderAudio(maxLevel, render);
  };

  renderAudio = async (maxLevel, render) => {
    if (maxLevel && render) {
      changeAudioSource(this.eventData.audioInputDeviceId.get(), (stream) => {
        //turn off any local audio track, if present
        this.removeActiveLocalAudioTrack();
        runInAction(() => {
          if (this.eventData.localAudioTrack !== stream) {
            this.eventData.localAudioTrack = stream;
            if (maxLevel && render) attachMicVolumeListener(stream, maxLevel, render);
          }
        });
      });
    } else {
      this.removeActiveLocalAudioTrack();
      await this.turnLocalAudioOn();
      this.updateMediaControlState();
    }
  };

  beginVideoPreview = (videoElementRef) => {
    changeVideoSource(this.eventData.videoDeviceId.get(), (stream) => {
      runInAction(() => {
        if (videoElementRef.srcObject !== stream) {
          this.assignNewLocalVideoTrack(stream);
          videoElementRef.srcObject = stream;
        }
      });
    });
  };

  setCurrentAudioInputDeviceId = (audioInputDeviceId) => {
    debug('setting audio input device id: ' + audioInputDeviceId);
    return new Promise((resolve) => {
      if (
        audioInputDeviceId &&
        this.eventData.audioInputDeviceId.get() !== audioInputDeviceId
      ) {
        runInAction(() => {
          this.eventData.audioInputDeviceId.set(audioInputDeviceId);
          assignDefaultAudioInputDeviceId(audioInputDeviceId);

          if (this.meetingIsLive()) {
            //audio device changed, turn off the old track and publish new track
            this.turnLocalAudioOff();
            this.turnLocalAudioOn(audioInputDeviceId);
          }
        });

        resolve();
      }
    });
  };

  setCurrentAudioOutputDeviceId = (audioOutputDeviceId) => {
    return new Promise((resolve) => {
      if (
        audioOutputDeviceId &&
        this.eventData.audioOutputDeviceId.value !== audioOutputDeviceId
      ) {
        runInAction(() => {
          this.eventData.audioOutputDeviceId.set(audioOutputDeviceId);
          assignDefaultAudioOutputDeviceId(audioOutputDeviceId);

          if (this.meetingIsLive()) {
            //audio sink changed, update audio sink ids for all participant audio references
            this.changeAllParticipantAudioOutput();
          }
        });

        resolve();
      }
    });
  };

  setCurrentVideoDeviceId = (videoDeviceId) => {
    return new Promise((resolve) => {
      if (videoDeviceId && this.eventData.videoDeviceId.get() !== videoDeviceId) {
        runInAction(() => {
          this.eventData.videoDeviceId.set(videoDeviceId);
          assignDefaultVideoInputDeviceId(videoDeviceId);

          if (this.meetingIsLive()) {
            //video device changed, turn off the old track and publish new track
            this.turnLocalVideoOff();
            this.turnLocalVideoOn(videoDeviceId);
          }
        });

        resolve();
      }
    });
  };

  addOrUpdateParticipantAudioTrack = (track, participant) => {
    if (!track || !track.sid) {
      return;
    }
    runInAction(() => {
      var audioTrack = this.eventData.participantsAudioTracks.find(
        (at) => at.sid === track.sid
      );
      if (audioTrack) {
        audioTrack.state = ACTIVE;
      } else {
        this.eventData.participantsAudioTracks.push({
          sid: track.sid,
          participant: participant?.sid,
          state: ACTIVE,
        });
      }
    });

    if (this.audioTrackChangeListener) this.audioTrackChangeListener();
  };

  registerAudioTrackChangeListener = (listener) => {
    runInAction(() => {
      this.audioTrackChangeListener = listener;
    });
  };

  removeParticipantAudioTrack = (participant) => {
    runInAction(() => {
      var audioTracks = this.eventData.participantsAudioTracks.filter(
        (at) => at.participant === participant.sid
      );
      if (audioTracks) {
        audioTracks.forEach((audioTrack) => {
          audioTrack.state = INACTIVE;
        });
      }

      if (this.audioTrackChangeListener) this.audioTrackChangeListener();
    });
  };

  removeParticipant = (participant) => {
    runInAction(() => {
      let participants = this.eventData.participants;
      var existing = participants.find((p) => p.sid === participant.sid);
      if (existing) {
        //remove
        var index = participants.indexOf(existing);
        if (index > -1) this.eventData.participants.splice(index, 1);
      }
    });
  };

  //callbacks
  onRoomConnected = (room) => {
    debug('Connected to room ', room.Name);
    this.setMeetingStatus(CONNECTED);

    runInAction(() => {
      var localParticipantVideoTracks = Array.from(
        room.localParticipant.videoTracks.values()
      );

      if (localParticipantVideoTracks.length) {
        this.assignNewLocalVideoTrack(localParticipantVideoTracks[0].track);
      }

      this.eventData.currentRoom = room;
      this.eventData.localParticipant.set(room.localParticipant);
      this.eventData.beforeUnloadHandler = this.endMeeting;
      this.eventData.visibilityChangeHandler = this.runWhenVisibilityChanges;

      this.updateAllMediaControlStates();
    });
  };

  onRoomDisconnected = (room) => {
    debug('Disconnected from  room ', room.name);
    this.setMeetingStatus(DISCONNECTED);

    runInAction(() => {
      this.removeActiveLocalVideoTrack();
      this.removeActiveLocalAudioTrack();
      this.eventData.currentRoom = null;
      this.eventData.beforeUnloadHandler = null;
      this.eventData.visibilityChangeHandler = null;
    });
  };

  onParticipantConnected = (participant) => {
    debug(`${participant.identity} connected`);
    runInAction(() => {
      this.eventData.participants.push(participant);
    });
  };

  onParticipantDisconnected = (participant) => {
    debug(`${participant.identity} disconnected`);

    runInAction(() => {
      if (
        !!participant &&
        !!this.eventData.activeParticipant.get() &&
        this.eventData.activeParticipant.get().sid === participant.sid &&
        this.eventData.isActiveParticipantPinned.get()
      ) {
        this.eventData.isActiveParticipantPinned.set(false);
        this.setCurrentActiveParticipant();
      }

      this.removeParticipant(participant);
    });
  };

  onParticipantSubscribedTrack = ({track, participant}) => {
    debug('yes subscribed track ' + track.kind + participant.sid);
    if (!participant) return;
    var isLocalParticipant = this.isLocalParticipant(participant);
    var trackIsLocalScreenCast = isLocalParticipant && this.isScreenCasting();
    if (track && track.kind === VIDEO && !trackIsLocalScreenCast) {
      //do not show your own screencast
      this.setActiveParticipant(participant, true, true);
    } else if (track && track.kind === AUDIO) {
      this.attachTrack(track, participant);
      this.addOrUpdateParticipantAudioTrack(track, participant);
    }

    if (isLocalParticipant && track) {
      runInAction(() => {
        switch (track.kind) {
          case AUDIO:
            this.assignNewLocalAudioTrack(track);
            break;
          case VIDEO:
            if (!trackIsLocalScreenCast) this.assignNewLocalVideoTrack(track);
            break;
          default:
            break;
        }
      });
    }
  };

  onParticipantUnsubscribedTrack = ({track, participant}) => {
    this.detachTrack(track, participant);
    if (this.isLocalParticipant(participant) && track) {
      runInAction(() => {
        switch (track.kind) {
          case AUDIO:
            this.removeActiveLocalAudioTrack();
            break;
          case VIDEO:
            this.removeActiveLocalVideoTrack();
            break;
          default:
            break;
        }
      });
    }

    if (track && track.kind === AUDIO) {
      this.removeParticipantAudioTrack(participant);
    }
  };

  onDominantSpeakerChanged = (participant) => {
    debug(`Dominant speaker changed to ${participant?.identity}`);
    runInAction(() => {
      if (
        !this.eventData.isActiveParticipantPinned.get() &&
        !(this.isLocalParticipant(participant) && this.isScreenCasting())
      )
        this.setCurrentActiveParticipant();
    });
  };

  onExistingParticipantsReportingComplete = (room) => {
    this.setCurrentActiveParticipant(room.dominantSpeaker, room.localParticipant);
    this.attachAnyPendingThumnailTracks();
    this.reportMeetingStartEvent(
      this.rootStore.UI.relayData.conferenceShortId,
      room.participants.size || 1
    );
  };

  reportError = (e) => {
    this.rootStore.reportError(e);
  };

  reportMeetingStartEvent = (confShortId, numOfParticipants) => {
    this.relayService
      .reportMeetingStartEvent(confShortId, numOfParticipants)
      .then(() => debug('Meeting start event reported'))
      .catch((e) => {
        debug(e);
        this.reportError(
          new Error('Error when attempting to report meeting start event')
        );
      });
  };

  reportMeetingCompleted = (confShortId, numOfParticipants, meetingDurationSeconds) => {
    return new Promise((resolve, reject) => {
      this.relayService
        .reportMeetingCompleted(confShortId, numOfParticipants, meetingDurationSeconds)
        .then(() => {
          resolve();
          debug('Meeting complete event reported');
        })
        .catch((e) => {
          reject(e);
          debug(e);
          this.reportError(
            new Error('Error when attempting to report meeting complete event')
          );
        });
    });
  };
}
