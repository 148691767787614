import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';

class MicEqualizer extends React.Component {
  render() {
    return (
      <svg
        focusable='false'
        viewBox='0 0 100 100'
        aria-hidden='true'
        height='100'
        width='100'
        style={{margin: '10px 0'}}>
        <defs>
          <clipPath id='level-indicator'>
            <rect
              x='0'
              y={this.props.maxLevel - this.props.currentTrackLevel}
              width={this.props.width}
              height={this.props.height}
            />
          </clipPath>
        </defs>
        <path
          fill='rgb(220, 220, 220)'
          d='m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z'></path>
        <path
          fill='rgb(220, 220, 220)'
          d='m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z'></path>
        <path
          fill='#080'
          clipPath='url(#level-indicator)'
          d='m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z'></path>
        <path
          fill='#080'
          clipPath='url(#level-indicator)'
          d='m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z'></path>
      </svg>
    );
  }
}

MicEqualizer.propTypes = {
  context: PropTypes.object.isRequired,
  maxLevel: PropTypes.number.isRequired,
  currentTrackLevel: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default inject('context')(observer(MicEqualizer));
