import React from 'react';
import {Route} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MeetingPage from './Pages/meetingPage';
import WelcomePage from './Pages/welcomePage';
import ErrorPage from './Pages/errorPage';
import PropTypes from 'prop-types';
import {WAITING_FOR_HOST} from './Constants/workflowStatus';
import {TERMINATED_BY_HOST} from './Constants/routeParams';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.validateMeetingCode = this.validateMeetingCode.bind(this);
    this.setCurrentAudioInputDeviceId = this.setCurrentAudioInputDeviceId.bind(this);
    this.setCurrentAudioOutputDeviceId = this.setCurrentAudioOutputDeviceId.bind(this);
    this.setCurrentVideoDeviceId = this.setCurrentVideoDeviceId.bind(this);
    this.getContextProps = this.getContextProps.bind(this);
    this.meetingIsLive = this.meetingIsLive.bind(this);
    this.markMediaSelectionComplete = this.markMediaSelectionComplete.bind(this);
    this.isMediaSelectionComplete = this.isMediaSelectionComplete.bind(this);
    this.startMeeting = this.startMeeting.bind(this);
    this.getMeetingPageProps = this.getMeetingPageProps.bind(this);
    this.setNumberOfModals = this.setNumberOfModals.bind(this);
    this.resumeAllAwaitingGuests = this.resumeAllAwaitingGuests.bind(this);
    this.isVideoPaused = this.isVideoPaused.bind(this);
    this.toggleFullScreenMode = this.toggleFullScreenMode.bind(this);
    this.updateTrackRefs = this.updateTrackRefs.bind(this);
    this.isLocalParticipant = this.isLocalParticipant.bind(this);
    this.isActiveParticipant = this.isActiveParticipant.bind(this);
    this.isActiveParticipantPinned = this.isActiveParticipantPinned.bind(this);
    this.isActiveVideoDetached = this.isActiveVideoDetached.bind(this);
    this.setActiveParticipant = this.setActiveParticipant.bind(this);
    this.registerAudioTrackChangeListener =
      this.registerAudioTrackChangeListener.bind(this);
    this.handleAudioTrackChange = this.handleAudioTrackChange.bind(this);
    this.onUnload = this.onUnload.bind(this);
    this.getDistinctParticipants = this.getDistinctParticipants.bind(this);

    this.state = {
      meetingCode: '',
      meetingCodeValidated: false,
      devicesLoading: false,
      devicesPopulated: false,
      redirectTo: null,
      activeParticipant: this.props.context.Conference.eventData.activeParticipant.get(),
      ...this.getContextProps(),
    };
  }
  handleAudioTrackChange = () => {
    this.setState({
      participantAudioTracks:
        this.props.context.Conference.eventData.participantsAudioTracks,
    });
  };
  registerAudioTrackChangeListener = () => {
    this.props.context.Conference.registerAudioTrackChangeListener(
      this.handleAudioTrackChange
    );
  };

  getContextProps = () => {
    return {
      numberOfModals: this.props.context.UI.relayData.localVideoInputDevices.length
        ? this.props.context.UI.relayData.localAudioInputDevices.length
          ? 3
          : 2
        : 1,
      hasValidSession: this.props.context.UI.sessionIsValid(),
      hasAudioOrVideoDevices: this.props.context.UI.hasAudioOrVideoDevices(),
      hasAudioInputDevices: this.props.context.UI.relayData.localAudioInputDevices.length,
      hasVideoDevices: this.props.context.UI.relayData.localVideoInputDevices.length,
      localVideoInputDevices: this.props.context.UI.relayData.localVideoInputDevices,
      localAudioInputDevices: this.props.context.UI.relayData.localAudioInputDevices,
      localAudioOutputDevices: this.props.context.UI.relayData.localAudioOutputDevices,
      currentAudioInputDevice:
        this.props.context.Conference.eventData.audioInputDeviceId.get(),
      currentAudioOutputDevice:
        this.props.context.Conference.eventData.audioOutputDeviceId.get(),
      currentVideoDevice: this.props.context.Conference.eventData.videoDeviceId.get(),
      status: this.props.context.UI.status,
      isMobile: this.props.context.Conference.eventData.isMobile,
      lastErrorMessage: this.props.context.UI.lastErrorMessage,
      meetingIsLive: this.meetingIsLive(),
      mediaSelectionComplete: this.props.context.UI.relayData.mediaSelectionComplete,
    };
  };

  setNumberOfModals = (num) => {
    this.setState({
      numberOfModals: num,
    });
  };

  onUnload = (e) => {
    e.preventDefault();
    this.props.context.Conference.runBeforeUnload().then(() => true);
  };

  componentDidMount() {
    this.setState({
      devicesLoading: true,
    });

    this.props.context.UI.populateAudioVideoDevices().then(() => {
      this.setState({
        devicesLoading: false,
        devicesPopulated: true,
        ...this.getContextProps(),
      });
    });

    this.registerAudioTrackChangeListener();
    window.addEventListener('unload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('unload', this.onUnload);
  }

  isMediaSelectionComplete = () => this.props.context.UI.isMediaSelectionComplete();

  validateMeetingCode = (meetingCode, onSuccess) => {
    this.props.context.UI.validateMeetingCode(meetingCode)
      .then((isValid) => {
        var isWaitingForHost = this.props.context.UI.status === WAITING_FOR_HOST;

        this.setState({
          meetingCode: meetingCode,
          meetingCodeValidated: isValid,
          waitingForHost: isWaitingForHost,
          waitingForHostMessage:
            (isWaitingForHost && this.props.context.UI.lastErrorMessage) || undefined,
          lastErrorMessage: this.props.context.UI.lastErrorMessage,
          ...this.getContextProps(),
        });

        if (onSuccess)
          onSuccess({
            isValid: isValid,
            waitingForHost: this.state.waitingForHost,
            waitingForHostMessage: this.state.waitingForHostMessage,
          });
      })
      .catch((e) => {
        this.props.context.UI.reportError(e);
      });
  };

  markMediaSelectionComplete = (onSuccess, onError) => {
    this.props.context.UI.markMediaSelectionComplete()
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch(() => {
        if (onError) onError();
      });

    this.setState({
      mediaSelectionComplete: this.props.context.UI.relayData.mediaSelectionComplete,
    });
  };

  startMeeting = (onSuccess, onError) => {
    this.setState({...this.getContextProps()});
    this.props.context.Conference.startMeeting()
      .then(() => {
        this.setState({...this.getContextProps()});
        if (onSuccess) onSuccess();
      })
      .catch((e) => {
        if (onError) onError(e);
        this.setState({...this.getContextProps()});
      });
  };

  resumeAllAwaitingGuests = () => {
    this.props.context.UI.resumeAllAwaitingGuests();
  };

  resumeMeetingForGuest = (onSuccess, onError) => {
    this.props.context.UI.resumeMeetingForGuest()
      .then(onSuccess)
      .catch((e) => {
        if (onError) {
          onError(e);
        }
      });
  };

  setCurrentAudioInputDeviceId = (audioInputDeviceId) => {
    this.props.context.Conference.setCurrentAudioInputDeviceId(audioInputDeviceId);
  };

  setCurrentAudioOutputDeviceId = (audioOutputDeviceId) => {
    this.props.context.Conference.setCurrentAudioOutputDeviceId(audioOutputDeviceId);
  };

  setCurrentVideoDeviceId = (videoDeviceId) => {
    this.props.context.Conference.setCurrentVideoDeviceId(videoDeviceId);
  };

  meetingIsLive = () => {
    return this.props.context.Conference.meetingIsLive();
  };

  updateTrackRefs = (sid, audioRef, videoRef) => {
    this.props.context.Conference.saveOrUpdateParticipantThumbnailTrackRefs(
      sid,
      audioRef,
      videoRef
    );
  };

  isLocalParticipant = (participant) =>
    this.props.context.Conference.isLocalParticipant(participant);

  isActiveParticipant = (participant) => {
    return (
      !!this.state.activeParticipant &&
      participant.sid === this.state.activeParticipant.sid
    );
  };

  isActiveParticipantPinned = () =>
    this.props.context.Conference.eventData.isActiveParticipantPinned;

  isActiveVideoDetached = (sid) =>
    this.props.context.Conference.isParticipantThumbnailVideoTrackDetached(sid);

  setActiveParticipant = (participant) => {
    this.props.context.Conference.setActiveParticipant(participant, true);
    this.setState({
      activeParticipant: this.props.context.Conference.eventData.activeParticipant.value,
    });
  };

  getDistinctParticipants = () => {
    var distinctKey = 'sid';
    var participants = this.props.context.Conference.eventData.participants;
    return [
      ...new Map(
        participants.map((participant) => [participant[distinctKey], participant])
      ).values(),
    ];
  };

  getMeetingPageProps = () => {
    return {
      meetingCode: this.state.meetingCode,
      participants: this.getDistinctParticipants(),
      activeParticipant: this.props.context.Conference.eventData.activeParticipant.get(),
      devicesLoading: this.state.devicesLoading,
      devicesPopulated: this.state.devicesPopulated,
      meetingCodeValidated: this.state.meetingCodeValidated,
      numberOfModals: this.props.context.UI.relayData.localVideoInputDevices.length
        ? this.props.context.UI.relayData.localAudioInputDevices.length
          ? 3
          : 2
        : 1,
      hasValidSession: this.props.context.UI.sessionIsValid(),
      hasAudioOrVideoDevices: this.props.context.UI.hasAudioOrVideoDevices() > 0,
      hasAudioInputDevices:
        this.props.context.UI.relayData.localAudioInputDevices.length > 0,
      hasVideoDevices: this.props.context.UI.relayData.localVideoInputDevices.length > 0,
      localVideoInputDevices: this.props.context.UI.relayData.localVideoInputDevices,
      localAudioInputDevices: this.props.context.UI.relayData.localAudioInputDevices,
      localAudioOutputDevices: this.props.context.UI.relayData.localAudioOutputDevices,
      currentAudioInputDevice:
        this.props.context.Conference.eventData.audioInputDeviceId.get(),
      currentAudioOutputDevice:
        this.props.context.Conference.eventData.audioOutputDeviceId.get(),
      participantAudioTracks:
        this.props.context.Conference.eventData.participantsAudioTracks,
      currentVideoDevice: this.props.context.Conference.eventData.videoDeviceId.get(),
      status: this.props.context.UI.status,
      isMobile: this.props.context.Conference.eventData.isMobile,
      lastErrorMessage: this.props.context.UI.lastErrorMessage,
      meetingIsLive: this.meetingIsLive(),
      mediaSelectionComplete: this.props.context.UI.relayData.mediaSelectionComplete,
      waitingForHost: this.state.waitingForHost,
      waitingForHostMessage: this.state.waitingForHostMessage,
      setJoinMeetingWithCameraTurnedOff:
        this.props.context.UI.setJoinMeetingWithCameraTurnedOff,
      joinMeetingWithCameraTurnedOff:
        this.props.context.UI.relayData.joinMeetingWithCameraTurnedOff,
      setJoinMeetingWithMicMuted: this.props.context.UI.setJoinMeetingWithMicMuted,
      joinMeetingWithMicMuted: this.props.context.UI.relayData.joinMeetingWithMicMuted,
      isVideoPaused: this.isVideoPaused,
      toggleFullScreenMode: this.toggleFullScreenMode,
      updateTrackRefs: this.updateTrackRefs,
      isLocalParticipant: this.isLocalParticipant,
      isActiveParticipant: this.isActiveParticipant,
      isActiveParticipantPinned: this.isActiveParticipantPinned,
      isActiveVideoDetached: this.isActiveVideoDetached,
      setActiveParticipant: this.setActiveParticipant,
    };
  };

  isVideoPaused = (participantSid) =>
    this.props.context.Conference.isParticipantVideoInPausedState(participantSid);

  toggleFullScreenMode = () => {
    if (this.props.fullScreen && this.props.fullScreen.active) {
      this.props.fullScreen.exit();
    } else {
      this.props.fullScreen.enter();
    }
  };

  render() {
    return (
      <div className='App'>
        <div className='container-fluid'>
          <Route
            exact
            path={['/', '/:extra']}
            render={(props) => (
              <WelcomePage
                meetingCodeValidated={this.state.meetingCodeValidated}
                meetingWasTerminatedByHost={
                  props.match.params.extra === TERMINATED_BY_HOST
                }
                sessionIsValid={this.state.hasValidSession}
                status={this.state.status}
                lastErrorMessage={this.state.lastErrorMessage}
                validateMeetingCode={this.validateMeetingCode}
                redirect={this.state.redirectTo}
                {...props}
                {...this.props}
              />
            )}
          />

          <Route
            path={['/join/:id', '/agentJoin/:id']}
            render={(props) => (
              <MeetingPage
                validateMeetingCode={this.validateMeetingCode}
                setCurrentAudioInputDeviceId={this.setCurrentAudioInputDeviceId}
                setCurrentAudioOutputDeviceId={this.setCurrentAudioOutputDeviceId}
                setCurrentVideoDeviceId={this.setCurrentVideoDeviceId}
                markMediaSelectionComplete={this.markMediaSelectionComplete}
                resumeAllAwaitingGuests={this.resumeAllAwaitingGuests}
                resumeMeetingForGuest={this.resumeMeetingForGuest}
                startMeeting={this.startMeeting}
                setNumberOfModals={this.setNumberOfModals}
                {...this.getMeetingPageProps()}
                {...props}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path='/error'
            render={(props) => (
              <ErrorPage
                status={this.state.status}
                lastErrorMessage={this.state.lastErrorMessage}
                {...props}
                {...this.props}
              />
            )}
          />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  context: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  fullScreen: PropTypes.object.isRequired,
};

export default inject('context')(observer(App));
