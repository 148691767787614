import React from 'react';
import {observer, inject} from 'mobx-react';
import Participant from './participant';
import PropTypes from 'prop-types';

const listContainerStyle = {
  display: 'flex',
  height: '120px',
  textAlign: 'center',
  marginTop: '5px',
  marginBottom: '5px',
};

const participantsContainerStyle = {
  flex: '1',
  maxWidth: '75%',
  margin: 'auto',
};

class ParticipantsList extends React.Component {
  constructor(props) {
    super(props);
    this.setActiveParticipant = this.setActiveParticipant.bind(this);
    this.isLocalParticipant = this.isLocalParticipant.bind(this);
  }

  componentDidMount() {
    this.props.context.Conference.attachAnyPendingThumnailTracks();
  }

  setActiveParticipant = (participant) => {
    this.props.setActiveParticipant(participant);
  };

  isLocalParticipant = (participant) => {
    return this.props.isLocalParticipant(participant);
  };

  render() {
    const participants = this.props.participants;
    return (
      <div className='row' id='container-participants-list' style={listContainerStyle}>
        <div
          id='participants'
          className='col-xs-12 col-sm-3 col-md-2'
          style={participantsContainerStyle}>
          {participants.map((participant) => (
            <Participant
              participant={participant}
              key={participant.sid}
              setActiveParticipant={this.setActiveParticipant}
              deviceType={this.props.deviceType}
              isVideoPaused={this.props.isVideoPaused}
              updateTrackRefs={this.props.updateTrackRefs}
              isLocalParticipant={this.props.isLocalParticipant}
              isActiveParticipant={this.props.isActiveParticipant}
              isActiveParticipantPinned={this.props.isActiveParticipantPinned}
              isActiveVideoDetached={this.props.isActiveVideoDetached}
              participantAudioTracks={this.props.participantAudioTracks}
            />
          ))}
        </div>
      </div>
    );
  }
}

ParticipantsList.propTypes = {
  context: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isVideoPaused: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  updateTrackRefs: PropTypes.func.isRequired,
  isLocalParticipant: PropTypes.func.isRequired,
  isActiveParticipant: PropTypes.func.isRequired,
  isActiveParticipantPinned: PropTypes.func.isRequired,
  isActiveVideoDetached: PropTypes.func.isRequired,
  setActiveParticipant: PropTypes.func.isRequired,
  participantAudioTracks: PropTypes.array.isRequired,
};

export default inject('context')(observer(ParticipantsList));
