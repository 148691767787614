import React from 'react';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import {red, green} from '@mui/material/colors';
import {PropTypes} from 'prop-types';

const defaultStyle = {
  position: 'absolute',
  top: '66%',
  margin: '0',
};

const ParticipantMediaIndicators = ({hasAudio}) => {
  return (
    <div style={defaultStyle}>
      {hasAudio && <MicIcon sx={{color: green[500]}} />}
      {!hasAudio && <MicOffIcon sx={{color: red[500]}} />}
    </div>
  );
};

ParticipantMediaIndicators.propTypes = {
  hasAudio: PropTypes.bool.isRequired,
};

export default ParticipantMediaIndicators;
