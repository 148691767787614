import ApiHelper from '../Helpers/apiHelper';

const {get, init, resetToken} = ApiHelper;

export default class RelayService {
  initialize = async () => {
    return new Promise((resolve, error) => {
      init().then(resolve).catch(error);
    });
  };

  getUserToken = async (beId, userIdentity, roomName) => {
    return new Promise((resolve, error) => {
      get(`token/GetVideoAccessToken/${userIdentity},${roomName},${beId}`)
        .then(resolve)
        .catch(error);
    });
  };

  resetToken = () => {
    resetToken();
  };

  getMeetingSessionDetails = async (relayConferenceId) => {
    return new Promise((resolve, error) => {
      get(`conference/GetMeetingSessionDetails/${relayConferenceId}`)
        .then(resolve)
        .catch(error);
    });
  };

  prepMeetingSession = async (relayConferenceId) => {
    return new Promise((resolve, error) => {
      get(`conference/PrepareNewSession/${relayConferenceId},${false}`)
        .then(resolve)
        .catch(error);
    });
  };

  reportMeetingCompleted = async (
    relayConferenceId,
    numOfParticipants,
    meetingDuration
  ) => {
    return new Promise((resolve, error) => {
      get(
        `conference/CompleteMeeting/${relayConferenceId},${numOfParticipants},${meetingDuration}`
      )
        .then(resolve)
        .catch(error);
    });
  };

  getConferenceDetails = async (conferenceRequestShortId, isAgent) => {
    return new Promise((resolve, error) => {
      get(`conferenceRequestData/${conferenceRequestShortId},${isAgent}`)
        .then(resolve)
        .catch(error);
    });
  };

  reportMeetingStartEvent = (confShortId, numOfParticipants) => {
    return new Promise((resolve, error) => {
      get(`conference/MarkNewSession/${confShortId},${numOfParticipants}`)
        .then((response) => resolve(response))
        .catch((e) => error(e));
    });
  };

  resumeAllAwaitingGuests = (conferenceShortId) => {
    return new Promise((resolve, error) => {
      get(`ResumeAllAwaitingGuests/${conferenceShortId}`)
        .then((response) => resolve(response))
        .catch((e) => error(e));
    });
  };

  registerGuestConnection = (confShortId, guestConnectionId) => {
    return new Promise((resolve, error) => {
      get(`RegisterGuestConnection/${confShortId},${guestConnectionId}`)
        .then((response) => resolve(response))
        .catch((e) => error(e));
    });
  };

  expungeGuestConnection = (confShortId, connectionId) => {
    var url = `ExpungeGuestConnection/${confShortId},${connectionId}`;
    return new Promise((resolve, error) => {
      get(url)
        .then((response) => resolve(response))
        .catch((e) => error(e));
    });
  };
}
