import React from 'react';
import {PropTypes} from 'prop-types';
import DotLoader from 'react-spinners/DotLoader';

export default class SpinLoader extends React.Component {
  render() {
    return (
      <div className='sweet-loading'>
        <div
          className='row'
          style={{
            margin: 'auto',
            width: '60%',
            padding: '60px',
          }}>
          <DotLoader
            /*  css={override} */
            size={20}
            color={'#123abc'}
            loading={this.props.show}
          />
          <span style={{display: 'inline'}}>&nbsp;&nbsp;&nbsp;</span>
          <span style={{display: this.props.show ? 'inline' : 'none'}}>
            {this.props.message}
          </span>
        </div>
      </div>
    );
  }
}

SpinLoader.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};
