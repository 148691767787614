import React from 'react';
import {detect} from 'detect-browser';
import ErrorMessage from '../Components/errorMessage';
import {setUserInfo, logError} from '../Loggers/raygunLogger';
import {debug as Ddebug} from 'debug';
import {ENABLE_DEBUG_MSGS} from '../Helpers/configHelper';

const getAppState = (rootStore) => {
  return {
    audioDevices: rootStore.UI.relayData.localAudioInputDevices,
    videoDevices: rootStore.UI.relayData.localVideoInputDevices,
    userAccessToken: rootStore.UI.relayData.userAccessToken,
    mediaSelectionComplete: rootStore.UI.relayData.mediaSelectionComplete,
  };
};

window.onerror = function (msg, src, lineno, colno, error) {
  logError({
    error,
    message: msg,
    source: src,
    lineNumber: lineno,
    columnNumber: colno,
  });

  return true;
};

var debugLogger = Ddebug('internal');
debugLogger.log = console.log.bind(console); // don't forget to bind to console!

if (ENABLE_DEBUG_MSGS) {
  Ddebug.enable('internal');
} else {
  window._relaydDebug = () => Ddebug.enable('internal');
}

window._relaydDebugDisable = () => Ddebug.disable();

export const handleError = (error, componentStack, rootStore) => {
  debugLogger(error);
  debugLogger(componentStack);

  setUserInfo({
    email: rootStore.UI.relayData.userEmail,
    fullName: rootStore.UI.relayData.userName,
    uuid: rootStore.UI.relayData.conferenceShortId, //Identifier
    conferenceRequestId: rootStore.UI.relayData.conferenceShortId,
    isAgent: rootStore.UI.relayData.isAgent,
  });

  var payload = {
    error,
    environment: process.env.REACT_APP_HOST_ENV,
    browser: detect(),
    customData: [{appState: getAppState(rootStore)}],
    componentStack,
  };

  logError(payload);
};

export const resetState = (rootStore) => {
  rootStore.resetState();
};

export const debug = (message) => {
  debugLogger(message);
};

export const ErrorFallback = () => (
  <ErrorMessage
    message='Something is wrong, please try refreshing the page'
    show={() => true}
  />
);
