import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  BTN_YES_TEXT,
  BTN_CANCEL_TEXT,
  RECORDING_CONSENT_MODAL_HEADER_TITLE,
  RECORDING_CONSENT_MODAL_PROMPT_BODY,
} from '../../Constants/textResources';

export default class RecordingConsentModal extends React.Component {
  render() {
    if (this.props.redirect) {
      return <Redirect to={this.props.redirect} />;
    } else {
      return ReactDOM.createPortal(
        <Modal
          show={true}
          backdrop='static'
          keyboard={false}
          centered={true}
          animation={true}>
          <Modal.Header>
            <Modal.Title>{RECORDING_CONSENT_MODAL_HEADER_TITLE}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{RECORDING_CONSENT_MODAL_PROMPT_BODY}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.onCancel}>
              {BTN_CANCEL_TEXT}
            </Button>
            <Button variant='primary' onClick={this.props.onConsentGranted}>
              {BTN_YES_TEXT}
            </Button>
          </Modal.Footer>
        </Modal>,
        document.getElementById('modal')
      );
    }
  }
}

RecordingConsentModal.propTypes = {
  onConsentGranted: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  redirect: PropTypes.string,
};
