import {makeAutoObservable} from 'mobx';
import UIStore from './uiStore';
import ConferenceStore from './conferenceStore';
import RealTimeStore from './realtimeStore';

class RootStore {
  constructor() {
    this.UI = new UIStore(this);
    this.Conference = new ConferenceStore(this);
    this.RealTime = new RealTimeStore(this);
    makeAutoObservable(this);
  }

  init = async () => {
    return new Promise((resolve, error) => {
      this.UI.init().then(resolve).catch(error);
    });
  };

  resetState = () => {
    this.UI.resetData();
    this.Conference.resetEventData();
  };

  reportError = (e) => {
    if (!e) return;
    setTimeout(() => {
      throw e;
    }, 100);
  };
}

export default new RootStore();
