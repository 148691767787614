import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {observer, inject} from 'mobx-react';
import MicEqualizer from './micEqualizer';
import {
  MEDIA_SELECTOR_CHOOSE_MIC,
  MEDIA_SELECTOR_CHOOSE_SPEAKER,
  MEDIA_SELECTOR_JOIN_MEETING_WITH_MIC_MUTED,
  SELECT_AUDIO_MICROPHONE,
  SELECT_AUDIO_SPEAKER,
} from '../Constants/textResources';

class SelectAudio extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      maxMicLevel: 100,
      currentTrackLevel: 0,
    };

    this.handleInputDeviceChange = this.handleInputDeviceChange.bind(this);
    this.handleOutputDeviceChange = this.handleOutputDeviceChange.bind(this);
    this.startPreview = this.startPreview.bind(this);
    this.refreshMicLevel = this.refreshMicLevel.bind(this);
  }

  startPreview = () => {
    this.props.context.Conference.beginAudioPreview(
      this.state.maxMicLevel,
      this.refreshMicLevel
    );
  };

  refreshMicLevel = (level) => {
    if (this._isMounted) {
      this.setState({
        currentTrackLevel: level,
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;

    if (this.props.localAudioInputDevices && this.props.localAudioInputDevices.length)
      this.props.setCurrentAudioInputDeviceId(
        this.props.localAudioInputDevices[0].deviceId
      );

    if (this.props.localAudioOutputDevices && this.props.localAudioOutputDevices.length)
      this.props.setCurrentAudioOutputDeviceId(
        this.props.localAudioOutputDevices[0].deviceId
      );
    this.startPreview();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputDeviceChange = (event) => {
    this.props.setCurrentAudioInputDeviceId(event.target.value);
    this.startPreview();
  };

  handleOutputDeviceChange = (event) => {
    this.props.setCurrentAudioOutputDeviceId(event.target.value);
  };

  handleMuteMicToggle = () => {
    this.props.setJoinMeetingWithMicMuted(!this.props.joinMeetingWithMicMuted);
  };

  render() {
    return (
      <div>
        <div className='modal-content'>
          <div className='modal-body' style={{textAlign: 'center'}}>
            {this.props.localAudioInputDevices &&
              this.props.localAudioInputDevices.length && (
                <div>
                  <label htmlFor='mic-select'>
                    <h6>{MEDIA_SELECTOR_CHOOSE_MIC}</h6>
                  </label>

                  {this.props.localAudioInputDevices.length > 1 && (
                    <select
                      id='mic-select'
                      style={{
                        width: '100%',
                        height: '28px',
                        marginBottom: '10px',
                      }}
                      defaultValue={this.props.localAudioInputDevices[0]}
                      onChange={this.handleInputDeviceChange}>
                      {this.props.localAudioInputDevices.map(({deviceId, label}, i) => (
                        <option value={deviceId} key={i}>
                          {label}
                        </option>
                      ))}
                    </select>
                  )}
                  {this.props.localAudioInputDevices.length === 1 && (
                    <h7>
                      {SELECT_AUDIO_MICROPHONE +
                        ' - ' +
                        this.props.localAudioInputDevices[0].label}
                    </h7>
                  )}
                  <div id='tgl-mute-mic-container'>
                    <Toggle
                      id='tgl-mute-mic'
                      defaultChecked={this.props.joinMeetingWithMicMuted}
                      onChange={this.handleMuteMicToggle}
                    />
                    <label id='label-tgl-mute-mic' htmlFor='tgl-mute-mic'>
                      {MEDIA_SELECTOR_JOIN_MEETING_WITH_MIC_MUTED}
                    </label>
                  </div>
                  <div id='mic-preview-container'>
                    <MicEqualizer
                      maxLevel={this.state.maxMicLevel}
                      currentTrackLevel={this.state.currentTrackLevel}
                      height={100}
                      width={100}
                    />
                  </div>
                </div>
              )}

            {this.props.localAudioOutputDevices &&
              this.props.localAudioOutputDevices.length && (
                <div id='speaker-select-container'>
                  <label htmlFor='speaker-select'>
                    <h6>{MEDIA_SELECTOR_CHOOSE_SPEAKER}</h6>
                  </label>
                  {this.props.localAudioOutputDevices.length > 1 && (
                    <select
                      id='speaker-select'
                      style={{
                        width: '100%',
                        height: '28px',
                        marginBottom: '20px',
                      }}
                      defaultValue={this.props.localAudioOutputDevices[0]}
                      onChange={this.handleOutputDeviceChange}>
                      {this.props.localAudioOutputDevices.map(({deviceId, label}, i) => (
                        <option value={deviceId} key={i}>
                          {label}
                        </option>
                      ))}
                    </select>
                  )}
                  {this.props.localAudioOutputDevices.length === 1 && (
                    <h7>
                      {SELECT_AUDIO_SPEAKER +
                        ' - ' +
                        this.props.localAudioOutputDevices[0].label}
                    </h7>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

SelectAudio.propTypes = {
  context: PropTypes.object.isRequired,
  setCurrentAudioInputDeviceId: PropTypes.func.isRequired,
  setCurrentAudioOutputDeviceId: PropTypes.func.isRequired,
  localAudioInputDevices: PropTypes.array.isRequired,
  localAudioOutputDevices: PropTypes.array.isRequired,
  setJoinMeetingWithMicMuted: PropTypes.func.isRequired,
  joinMeetingWithMicMuted: PropTypes.bool.isRequired,
};

export default inject('context')(observer(SelectAudio));
