import React from 'react';
import {Redirect} from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import {FullScreen} from 'react-full-screen';
import Nav from '../Components/nav';
import MediaSelector from '../Components/mediaSelector';
import MeetingView from '../Components/MeetingView';
import ErrorMessage from '../Components/errorMessage';
import Button from 'react-bootstrap/Button';
import SpinLoader from '../Components/spinLoader';
import {
  SKIP_MEDIA_SELECTION_FOR_MOBILE,
  REALTIME_RECONNECT_ATTEMPTS,
  REALTIME_HOST_STATUS_POLLING_INTERVAL_SECONDS,
  PROMPT_RECORDING_CONSENT,
} from '../Helpers/configHelper';
import {ERROR, MEETING_INVALIDATED} from '../Constants/workflowStatus';
import PropTypes from 'prop-types';
import {
  MEETING_PAGE_LOADER_MSG,
  MEETING_PAGE_CONNECTING,
  MEETING_PAGE_REQUEST_TO_JOIN,
} from '../Constants/textResources';
import {INVALID_MEETING_CODE,BTN_BACK_TEXT} from '../Constants/textResources';

class MeetingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingCode: this.props.match.params.id || this.props.meetingCode,
      devicesPopulated: this.props.devicesPopulated,
      meetingCodeValidated: this.props.meetingCodeValidated,
      mediaSelectionComplete: this.props.mediaSelectionComplete,
      resumeAllAwaitingGuests: this.props.resumeAllAwaitingGuests,
      numberOfModals: this.props.numberOfModals,
      hasValidSession: this.props.hasValidSession,
      hasAudioOrVideoDevices: this.props.hasAudioOrVideoDevices,
      hasAudioInputDevices: this.props.hasAudioInputDevices,
      hasVideoDevices: this.props.hasVideoDevices,
      meetingIsLive: this.props.meetingIsLive,
      hideMediaSelector: false,
      showSpinner: false,
      showMeetingView: false,
      userName: null,
      loaderMessage: MEETING_PAGE_LOADER_MSG,
    };

    this.markMediaSelectionDone = this.markMediaSelectionDone.bind(this);
    this.isAgent = this.isAgent.bind(this);
    this.setUserType = this.setUserType.bind(this);
    this.needsUserInput = this.needsUserInput.bind(this);
    this.startAndShowMeetingView = this.startAndShowMeetingView.bind(this);
    this.notifyAllGuestsWhenAgentJoinsMeeting =
      this.notifyAllGuestsWhenAgentJoinsMeeting.bind(this);
    this.startListeningForHostBroadcast = this.startListeningForHostBroadcast.bind(this);
  }

  startListeningForHostBroadcast = () => {
    //connect to signalR Hub
    this.props.context.RealTime.initConnection(REALTIME_RECONNECT_ATTEMPTS);

    //poll to check if host is ready for meeting
    var interval = setInterval(() => {
      if (this.props.context.RealTime.connectionState.hostIsReady) {
        this.props.resumeMeetingForGuest(() => {
          //onSuccess
          this.startAndShowMeetingView();
        });

        clearInterval(interval);
        interval = null;
      }
    }, REALTIME_HOST_STATUS_POLLING_INTERVAL_SECONDS * 1000);
  };

  componentDidMount() {
    if (!this.props.meetingCodeValidated) {
      this.setUserType();
      this.props.validateMeetingCode(this.state.meetingCode, (validationStatus) => {
        if (validationStatus.isValid) {
          //go ahead and connect
          this.setState({
            userName: this.props.context.UI.relayData.userName,
          });
        } else {
          this.setState({
            invalidMeetingCode: true,
            inputMode: false,
            showSpinner: false,
            errorMessage: INVALID_MEETING_CODE,
          });
        }
      });
    }
  }

  isAgent = () => this.props.context.UI.relayData.isAgent;

  setUserType = () => {
    this.props.context.UI.setUserType(window.location.pathname);
  };

  needsUserInput = () => {
    let isMobile = this.props.context.Conference.eventData.isMobile;

    return (
      !this.state.userName ||
      (this.state.userName && !this.state.userName.length) ||
      (this.props.hasAudioInputDevices &&
        !(
          isMobile &&
          SKIP_MEDIA_SELECTION_FOR_MOBILE &&
          this.props.localAudioInputDevices.length === 1
        )) ||
      (this.props.hasVideoDevices &&
        !(
          isMobile &&
          SKIP_MEDIA_SELECTION_FOR_MOBILE &&
          this.props.localVideoInputDevices.length === 1
        ))
    );
  };

  startAndShowMeetingView = () => {
    this.setState({
      loaderMessage: 'Joining room ... ',
      hideMediaSelector: true,
    });

    this.props.startMeeting(
      () => {
        //onSuccess
        this.setState({
          showSpinner: false,
          hideMediaSelector: true,
          showMeetingView: true,
        });
      },
      () => {
        //onError
        this.setState({
          showSpinner: false,
          hideMediaSelector: true,
        });
      }
    );
  };

  notifyAllGuestsWhenAgentJoinsMeeting = () => {
    if (this.isAgent()) {
      var interval = setInterval(() => {
        if (this.props.context.Conference.meetingIsLive()) {
          this.props.resumeAllAwaitingGuests();
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  markMediaSelectionDone = (messageToShow = '') => {
    this.setState({
      showSpinner: true,
      loaderMessage: messageToShow.length ? messageToShow : MEETING_PAGE_CONNECTING,
    });

    this.props.markMediaSelectionComplete(
      (result) => {
        //Success callback
        if (result.showMeetingView) {
          this.startAndShowMeetingView();
          this.notifyAllGuestsWhenAgentJoinsMeeting();
        } else if (!this.isAgent() && result.waitingForAgent) {
          this.startListeningForHostBroadcast();
          this.setState({
            loaderMessage: `${result.showMessage} ..`,
            hideMediaSelector: true,
          });
        }
      },
      () => {
        //Error callback
        this.setState({
          showSpinner: false,
          hideMediaSelector: true,
        });
      }
    );
  };

  showMediaSelector = () => {
    return (
      !this.props.mediaSelectionComplete &&
      !this.state.hideMediaSelector &&
      this.needsUserInput() === true
    );
  };

  redirectToHome = () => {
    this.setState({redirect: '/'});
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
  };
  
  render() {
    const showMediaSelector = this.showMediaSelector();
    const showSpinner = !this.state.meetingIsLive && this.state.showSpinner;
    const showMeetingView =
      (this.props.mediaSelectionComplete && this.props.meetingIsLive) ||
      this.state.showMeetingView;
    const showError =
      (this.props.status === ERROR || this.props.status === MEETING_INVALIDATED) &&
      this.props.lastErrorMessage;

    if (this.props.meetingCodeValidated && this.props.devicesPopulated) {
      return (
        <>
          <Nav styleOverride={{marginBottom: '20px'}} {...this.props} />
          {showMediaSelector && (
            <MediaSelector
              show={showMediaSelector}
              localVideoInputDevices={this.props.localVideoInputDevices}
              localAudioInputDevices={this.props.localAudioInputDevices}
              localAudioOutputDevices={this.props.localAudioOutputDevices}
              setCurrentAudioInputDeviceId={this.props.setCurrentAudioInputDeviceId}
              setCurrentAudioOutputDeviceId={this.props.setCurrentAudioOutputDeviceId}
              setCurrentVideoDeviceId={this.props.setCurrentVideoDeviceId}
              markMediaSelectionDone={this.markMediaSelectionDone}
              userName={this.state.userName}
              showRecordingConsent={PROMPT_RECORDING_CONSENT && !this.isAgent()}
              {...this.props}
            />
          )}

          {showSpinner && (
            <SpinLoader message={this.state.loaderMessage} show={showSpinner} />
          )}

          {!showMediaSelector && !showSpinner && !showMeetingView && (
            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
              }}>
              <Button onClick={this.markMediaSelectionDone}>
                {MEETING_PAGE_REQUEST_TO_JOIN}
              </Button>
            </div>
          )}

          {showMeetingView && (
            <FullScreen handle={this.props.fullScreen}>
              <MeetingView
                participants={this.props.participants}
                activeParticipant={
                  this.props.activeParticipant || this.props.participants[0]
                }
                show={showMeetingView}
                mediaSelectionComplete={this.props.mediaSelectionComplete}
                meetingIsLive={this.props.meetingIsLive}
                deviceType={this.props.deviceType}
                isMobile={this.props.isMobile}
                isVideoPaused={this.props.isVideoPaused}
                markMediaSelectionDone={this.markMediaSelectionDone}
                isFullScreenMode={this.props.fullScreen && this.props.fullScreen.active}
                toggleFullScreenMode={this.props.toggleFullScreenMode}
                localVideoInputDevices={this.props.localVideoInputDevices}
                localAudioInputDevices={this.props.localAudioInputDevices}
                localAudioOutputDevices={this.props.localAudioOutputDevices}
                currentAudioInputDevice={this.props.currentAudioInputDevice}
                currentAudioOutputDevice={this.props.currentAudioOutputDevice}
                currentVideoDevice={this.props.currentVideoDevice}
                setCurrentAudioInputDeviceId={this.props.setCurrentAudioInputDeviceId}
                setCurrentAudioOutputDeviceId={this.props.setCurrentAudioOutputDeviceId}
                setCurrentVideoDeviceId={this.props.setCurrentVideoDeviceId}
                updateTrackRefs={this.props.updateTrackRefs}
                isLocalParticipant={this.props.isLocalParticipant}
                isActiveParticipant={this.props.isActiveParticipant}
                isActiveParticipantPinned={this.props.isActiveParticipantPinned}
                isActiveVideoDetached={this.props.isActiveVideoDetached}
                setActiveParticipant={this.props.setActiveParticipant}
                participantAudioTracks={this.props.participantAudioTracks}
              />
            </FullScreen>
          )}

          {showError && (
            <ErrorMessage
              status={this.props.status}
              message={this.props.lastErrorMessage}
              show={() => true}
            />
          )}
        </>
      );
    } else {
      const pendingValidationOrDeviceIdLoadNoError =
        (!this.props.meetingCodeValidated || !this.props.devicesPopulated) &&
        this.props.status !== ERROR &&
        this.props.status !== MEETING_INVALIDATED;
      return (
        <>
        {this.renderRedirect()}
          <Nav {...this.props} />
          <SpinLoader
            message={this.state.loaderMessage}
            show={pendingValidationOrDeviceIdLoadNoError}
          />
          {showError && (
            <div
            style={{
              textAlign: 'center',
              margin: 'auto',
            }}>
           <ErrorMessage
             status={this.props.status}
             message={this.props.lastErrorMessage}
             show={() => true}
           />
          <Button
            id='btn-back-to-home'
            onClick={this.redirectToHome}
            style={{verticalAlign: 'baseline'}}>
            {BTN_BACK_TEXT}
          </Button>
           </div>
          )}
        </>
      );
    }
  }
}

MeetingPage.propTypes = {
  context: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  participants: PropTypes.array.isRequired,
  activeParticipant: PropTypes.object,
  localVideoInputDevices: PropTypes.array.isRequired,
  localAudioInputDevices: PropTypes.array.isRequired,
  localAudioOutputDevices: PropTypes.array.isRequired,
  currentAudioInputDevice: PropTypes.string,
  currentAudioOutputDevice: PropTypes.string,
  currentVideoDevice: PropTypes.string,
  numberOfModals: PropTypes.number.isRequired,
  meetingCode: PropTypes.string.isRequired,
  status: PropTypes.string,
  lastErrorMessage: PropTypes.string,
  devicesPopulated: PropTypes.bool.isRequired,
  meetingCodeValidated: PropTypes.bool.isRequired,
  mediaSelectionComplete: PropTypes.bool.isRequired,
  hasValidSession: PropTypes.bool.isRequired,
  hasAudioOrVideoDevices: PropTypes.bool.isRequired,
  hasAudioInputDevices: PropTypes.bool.isRequired,
  hasVideoDevices: PropTypes.bool.isRequired,
  meetingIsLive: PropTypes.bool.isRequired,
  validateMeetingCode: PropTypes.func.isRequired,
  markMediaSelectionComplete: PropTypes.func.isRequired,
  startMeeting: PropTypes.func.isRequired,
  setCurrentAudioInputDeviceId: PropTypes.func.isRequired,
  setCurrentAudioOutputDeviceId: PropTypes.func.isRequired,
  setCurrentVideoDeviceId: PropTypes.func.isRequired,
  setNumberOfModals: PropTypes.func.isRequired,
  resumeAllAwaitingGuests: PropTypes.func.isRequired,
  resumeMeetingForGuest: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isVideoPaused: PropTypes.func.isRequired,
  fullScreen: PropTypes.object.isRequired,
  toggleFullScreenMode: PropTypes.func.isRequired,
  setJoinMeetingWithCameraTurnedOff: PropTypes.func.isRequired,
  joinMeetingWithCameraTurnedOff: PropTypes.bool.isRequired,
  setJoinMeetingWithMicMuted: PropTypes.func.isRequired,
  joinMeetingWithMicMuted: PropTypes.bool.isRequired,
  updateTrackRefs: PropTypes.func.isRequired,
  isLocalParticipant: PropTypes.func.isRequired,
  isActiveParticipant: PropTypes.func.isRequired,
  isActiveParticipantPinned: PropTypes.func.isRequired,
  isActiveVideoDetached: PropTypes.func.isRequired,
  setActiveParticipant: PropTypes.func.isRequired,
  participantAudioTracks: PropTypes.array,
};

export default inject('context')(observer(MeetingPage));
