import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {
  FULLSCREEN_CONTROL_ENTER_FULLSCREEN,
  FULLSCREEN_CONTROL_EXIT_FULLSCREEN,
} from '../Constants/textResources';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';

const btnStyle = {
  background: 'none',
  verticalAlign: 'top',
};

const iconStyle = {
  background: 'black',
  fontSize: '3.2rem',
  color: 'white',
};

class FullScreenControl extends React.Component {
  constructor(props) {
    super(props);
    this.getControl = this.getControl.bind(this);
    this.getImageAltText = this.getImageAltText.bind(this);
  }

  getImageAltText = () =>
    this.props.isFullScreenMode
      ? FULLSCREEN_CONTROL_EXIT_FULLSCREEN
      : FULLSCREEN_CONTROL_ENTER_FULLSCREEN;

  getControl = () => (
    <div style={{marginLeft: '-8px'}}>
      <button
        id='btn-fullScreen-on-off'
        style={{
          verticalAlign: 'text-bottom',
          marginLeft: '-6px',
          ...btnStyle,
        }}
        title={this.getImageAltText()}
        onClick={this.props.toggleFullScreenMode}>
        {this.props.isFullScreenMode ? (
          <CloseFullscreenRoundedIcon sx={iconStyle} />
        ) : (
          <FullscreenRoundedIcon sx={iconStyle} />
        )}
      </button>
    </div>
  );

  render() {
    return this.getControl();
  }
}

FullScreenControl.propTypes = {
  context: PropTypes.object.isRequired,
  toggleFullScreenMode: PropTypes.func.isRequired,
  isFullScreenMode: PropTypes.bool.isRequired,
};

export default inject('context')(observer(FullScreenControl));
