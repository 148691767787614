import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {Checkmark} from 'react-checkmark';
import {
  DEVICES_SELECT_SELECT_VIDEO_DEVICES,
  DEVICES_SELECT_SELECT_CAMERA,
} from '../../Constants/textResources';
import DeviceListMenuTriggerButton from '../DeviceListMenuTriggerButton';

const VideoDevicesSelect = ({
  videoDevices,
  currentVideoDevice,
  onClick,
  isFullScreenMode,
  mediaControlContainerRef,
}) => {
  const [currentVideoDeviceId, setCurrentVideoDeviceId] = useState(currentVideoDevice);
  const [open, setOpen] = useState(false);

  var getCheckmark = () => <Checkmark size='small' color='#223344' />;

  var getVideoDeviceListMenu = () => (
    <div>
      <div>
        <span className='devices-select-header'>
          <strong>{DEVICES_SELECT_SELECT_CAMERA}</strong>
        </span>
      </div>
      <div className='menu'>
        {videoDevices.map((device) => (
          <div
            className='devices-menu-item-container'
            onClick={() => {
              setCurrentVideoDeviceId(device.deviceId);
              onClick(device.deviceId);
            }}
            key={device.deviceId}>
            {device.deviceId === currentVideoDeviceId && (
              <span style={{float: 'left', marginLeft: '-2px'}}>{getCheckmark()}</span>
            )}
            <span
              className='menu-item'
              style={{
                marginLeft: device.deviceId === currentVideoDeviceId ? '0px' : '18px',
              }}>
              {device.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  var getTrigger = (isOpen) => (
    <DeviceListMenuTriggerButton
      ref={isFullScreenMode ? mediaControlContainerRef : null}
      open={isOpen}
      setOpen={setOpen}
      btnId={'btn-video-device-list'}
      imgTitle={DEVICES_SELECT_SELECT_VIDEO_DEVICES}
    />
  );

  return (
    <Popup trigger={(open) => getTrigger(open)} open={open} position='top left'>
      {videoDevices.length > 1 && getVideoDeviceListMenu()}
    </Popup>
  );
};

VideoDevicesSelect.propTypes = {
  videoDevices: PropTypes.array.isRequired,
  currentVideoDevice: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isFullScreenMode: PropTypes.bool.isRequired,
  mediaControlContainerRef: PropTypes.object.isRequired,
};

export default VideoDevicesSelect;
